<template>
  <div class="custom-img-view">
    <div class="custom-image-viewer__mask"></div>
    <div class="custom-image-viewer__canvas" @click="clickViewer">
      <div class="close-right" id="close-right">
        <div class="close-btn" @click="closeViewer" v-if="!loading">
          <i class="el-icon-close"></i>
        </div>
      </div>
      <img id="ocrImage" :src="src" ref="ocrImage"  @load="getImageSize">
      <div class="custom-footer" id="custom-footer" @click="ocrFun" v-show="showOcrBtn">
        <div class="ocr-btn" id="ocr-btn">
          <img src="../assets/images/loading.gif" v-if="loading" >
          <span id="text">{{ ocrText }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "customImgView",
  props:{
    src:{
      type:String,
      default:""
    },
    loading:{
      type:Boolean,
      default:false
    },
    ocrText:{
      type:String,
      default:""
    },
    showOcrBtn:{
      type:Boolean,
      default:true
    }
  },
  methods:{
    closeViewer(){
      this.$emit('closeViewer')
    },
    ocrFun(){
      if (this.loading){
        return
      }
      this.$emit('identifyOcr')
    },
    getImageSize(){
      // const img = this.$refs.ocrImage;
      const width = document.getElementById('ocrImage').width;
      let dom = document.getElementById('close-right');
      if (dom){
        dom.style.width = width + 50 + 'px';
        dom.style.marginBottom = '-10px'
      }
      let footer = document.getElementById('custom-footer');
      if (footer){
        footer.style.width = width + 20 + 'px'
      }
    },
    handleEscKey(event){
      if (event.key === 'Escape' || event.keyCode === 27) {
        this.closeViewer()
      }
    },
    clickViewer(e){
      if (e.target.id == 'ocrImage' || e.target.id == 'custom-footer' || e.target.id == 'ocr-btn' || e.target.id == 'text'){
        return
      } else {
        this.closeViewer()
      }
    }
  },
  mounted() {
    document.addEventListener('keydown', this.handleEscKey);
  },
  beforeDestroy() {
    document.removeEventListener('keydown',this.handleEscKey)
  }
};
</script>

<style scoped lang="less">
.custom-img-view{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  .custom-image-viewer__mask{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.5;
    background-color: #000000;
  }
  .close-right{
    display: flex;
    justify-content: flex-end;
  }
  .close-btn{
    //position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    top: 40px;
    right: 40px;
    width: 30px;
    height: 30px;
    background-color: #ffffff;
    i{
      font-size: 20px;
      color: #000000;
    }
  }
  .custom-image-viewer__canvas{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    #ocrImage{
      transform: scale(1) rotate(0deg);
      margin-left: 0px;
      margin-top: 0px;
      max-height: 80%;
      max-width: 80%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border: 10px solid #ffffff;
    }
  }
  .custom-footer{
    //position: absolute;
    left:50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    bottom: 30px;
    //transform: translateX(-50%);
    height: 40px;
    //padding: 0 23px;
    background-color: #E0E8FF;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    .ocr-btn{
      display: flex;
      align-items: center;
      color: #366aff;
    }
    /deep/img{
      width: 14px;
      height: 14px;
      margin-right: 10px;
    }
  }
}
</style>