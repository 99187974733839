import { render, staticRenderFns } from "./messageContent.vue?vue&type=template&id=823707da&scoped=true"
import script from "./messageContent.vue?vue&type=script&lang=js"
export * from "./messageContent.vue?vue&type=script&lang=js"
import style0 from "./messageContent.vue?vue&type=style&index=0&id=823707da&prod&lang=less&scoped=true"
import style1 from "./messageContent.vue?vue&type=style&index=1&id=823707da&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "823707da",
  null
  
)

export default component.exports