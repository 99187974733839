<template>
  <div id="knowledgeTable">
    <audio src=""></audio>
    <div class="table" v-if="tableData.length > 0">
      <el-table :data="tableData" style="width: 100%"
        :header-cell-style="{ backgroundColor: '#F6F8FD', height: '50px', color: '#000000', fontWeight: 400 }"
        height="100%" @selection-change="handleSelectionChange" @row-click="rowClick" :row-key="bridgecode"
        ref="knowledgeTable" :header-cell-class-name="cellClass" :row-class-name="tableRowClassName"
        :row-style="{ cursor: 'pointer', color: '#000000' }" :highlight-current-row="true" v-loading="loading">
        <!-- 同步弹框 -->
        <el-table-column type="selection" width="55" align="center" :selectable="selectable" v-if="synchronizeFiles">
        </el-table-column>
        <!-- 企业知识库 -->
        <!-- :selectable="onlySeePermission" -->
        <el-table-column type="selection" width="55" align="center" v-if="!synchronizeFiles && !isAskLight()">
        </el-table-column>
        <el-table-column fixed prop="name" :show-overflow-tooltip="true" min-width="350px" class-name="file-name"
          :label="$t('knowledgeManagement.knowledgeTable.name')">
          <template slot-scope="scope">
            <div class="docType">
              <div class="docLeft" @click.stop="nextOrPreview(scope.row)">
                <template v-if="scope.row.type == 1">
                  <img src="../../assets/images/video.png" v-if="setSrc(scope.row.fileSuffix) == 'video'" />
                  <img src="../../assets/images/img.png" v-else-if="setSrc(scope.row.fileSuffix) == 'image'" />
                  <img src="../../assets/images/txt.png" v-else-if="setSrc(scope.row.fileSuffix) == 'txt'" />
                  <img src="../../assets/images/doc.png"
                    v-else-if="setSrc(scope.row.fileSuffix) == 'word' || (scope.row.fileSuffix && scope.row.fileSuffix.includes('文档') && scope.row.type == 1)" />
                  <img src="../../assets/images/pdf.png" v-else-if="setSrc(scope.row.fileSuffix) == 'pdf'" />
                  <img src="../../assets/images/ppt.png" v-else-if="setSrc(scope.row.fileSuffix) == 'ppt'" />
                  <img src="../../assets/images/xls.png" v-else-if="setSrc(scope.row.fileSuffix) == 'excel'" />
                  <img src="../../assets/images/html.png" v-else-if="setSrc(scope.row.fileSuffix) == 'html'" />
                  <img src="../../assets/images/mp3.png" v-else-if="setSrc(scope.row.fileSuffix) == 'audio'" />
                  <img src="../../assets/images/other.png" v-else>
                </template>
                <img v-if="scope.row.type == 0" src="../../assets/images/file.png" class="folder-icon">
                <span @click.stop="lookDetail(scope.row)" v-show="scope.row.type == 1" class="docName">{{
                  scope.row.name }}</span>
                <span v-show="scope.row.type == 0" class="docName">{{ scope.row.name }}</span>
                <div class="version-num" v-if="scope.row.type == 1 && !synchronizeFiles">V{{ scope.row.version }}</div>
              </div>
              <div v-if="scope.row.associatedSyncStatus" class="associatedSyncStatus">
                <span>{{ synchronizeFiles ? '已关联同步' : '关联同步' }}</span>
              </div>
              <template v-if="scope.row.type == 1 && !synchronizeFiles">
                <template v-if="scope.row.previousPubVersion">
                  <div class="previousStatus">
                    {{ $t('status.publish') }}V{{ scope.row.previousPubVersion.no }}
                  </div>
                </template>
                <template v-else-if="scope.row.currentVersion">
                  <div
                    :class="['newsStatus', !scope.row.currentVersion.state || scope.row.currentVersion.state === 'UNPUBLISHED' ?
                      'unPublishStatus' : (scope.row.currentVersion.state === 'PUBLISH' || scope.row.currentVersion.state === 'PUBLISHING' ? 'publishStatus' : 'archiveStatus')]">
                    {{ scope.row.currentVersion.state | statusFilter }} </div>
                </template>
                <template v-else>
                  <div class="newsStatus unPublishStatus">{{ $t('status.unPublish') }}</div>
                </template>
              </template>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="fileSuffix" label="" align="center" width="90">
          <template slot-scope="scope">
            <template v-if="scope.row.type == 0">
              <el-popover
                placement="right"
                width="280"
                :close-delay="200"
                :open-delay="300"
                popper-class="folder-status-config-popper"
                @after-enter="showFolderStatus(scope.row)"
                @hide="hideFolderStatus(scope.row)"
                trigger="hover">
                <div v-show="changeAsynStatusFooterVisible" class="folder-status-config">
                  <div class="folder-status-config-header">
                    <div class="labelLeft">
                      <div :class="['evaluate-table-switch', activeSyncInfo.parentSyncStatus == 1&&'disable-evaluate-table-switch']">
                        <el-switch
                            @click.native.prevent="changeSyncEnable(activeSyncInfo, 'switch')"
                            :width="42"
                            v-model="activeSyncInfo.syncEnable"
                            active-color="#366AFF"
                            inactive-color="#E2E2E2">
                        </el-switch>
                        <span
                            class="switch-open-icon"
                            @click="changeSyncEnable(activeSyncInfo, 'open')"
                            v-if="activeSyncInfo.syncEnable">
                          <i class="iconfont guoran-a-16-17"></i>
                        </span>
                        <span
                            class="switch-close-icon"
                            @click="changeSyncEnable(activeSyncInfo, 'close')"
                            v-if="!activeSyncInfo.syncEnable">
                          <i class="arsenal_icon arsenalcuo1"></i>
                        </span>
                      </div>
                      <span class="tips-text">{{$t('AISearch.SyncAISearch')}}</span>
                    </div>
                  </div>
                  <div v-if="activeSyncInfo.syncEnable" class="folder-status-config-local">
                    <span class="fscl-title">
                      {{$t('AISearch.aisearchPath')}}
                    </span>
                    <span class="fscl-title-tip">
                      {{$t('AISearch.pathDescription')}}
                    </span>
                    <el-input size="mini" @input="validateInput" :disabled="activeSyncInfo.parentSyncStatus == 1" v-model="activeSyncInfo.syncPath" :placeholder="$t('AISearch.enterPlaceholder')"></el-input>
                    <span v-show="activeSyncInfo.parentSyncStatus != 1" class="fscl-tip">
                      {{$t('AISearch.syncConditionWhenKnowledgeExists')}}
                    </span>
                    <span v-show="activeSyncInfo.parentSyncStatus == 1" class="fscl-tip">
                      {{$t('AISearch.cannotChangeIfSyncedByName')}}
                    </span>
                  </div>
                  <div v-show="activeSyncInfo.parentSyncStatus != 1" class="folder-status-config-footer">
                    <span @click="changeAsynStatus('cancel')" class="fscf-concel">{{$t('common.cancel')}}</span>
                    <span @click="changeAsynStatus('save')" class="fscf-confirm">{{$t('common.save')}}</span>
                  </div>
                </div>
                <div slot="reference" class="folder-status">
                  <span v-if="scope.row.syncStatus == 1" class="folder-status-label folder-status-synchronization">{{$t('AISearch.SyncEnable')}}</span>
                  <span v-if="scope.row.syncStatus == 0" class="folder-status-label folder-status-nosynchronization">{{$t('AISearch.SyncNoEnable')}}</span>
                </div>
              </el-popover>
            </template>
            <template v-else>
              <el-popover
                placement="right"
                width="280"
                :close-delay="200"
                :open-delay="300"
                popper-class="folder-status-config-popper"
                @after-enter="showFolderStatus(scope.row)"
                @hide="hideFolderStatus(scope.row)"
                trigger="hover">
                <div v-show="changeAsynStatusFooterVisible" class="folder-status-config">
                  <div v-show="activeSyncInfo.syncStatus == 2" class="folder-status-config-header-tip">
                    <div class="fscht-text">{{$t('AISearch.Pleasewaitwhilesynchronizing')}}</div>
                    <div class="fscht-handle">
                      <img height="15" src="./../../assets/images/loading3.gif" alt="">
                    </div>
                  </div>
                  <div v-show="activeSyncInfo.syncStatus == 4" class="folder-status-config-header-tip-err">
                    <div class="fscht-text">{{$t('AISearch.Failedtosynchronizetryagain')}}</div>
                    <div @click="reSyncExecute" class="fscht-handle-err">
                      {{$t('AISearch.Sync')}}
                    </div>
                  </div>
                  <div v-show="activeSyncInfo.syncStatus == 5" class="folder-status-config-header-tip">
                    <div class="fscht-text">{{$t('AISearch.PubsyncTip')}}</div>
                    <div class="fscht-handle">
                    </div>
                  </div>
                  <div class="folder-status-config-header">
                    <div class="labelLeft">
                      <div :class="['evaluate-table-switch',  activeSyncInfo.parentSyncStatus == 1&&'disable-evaluate-table-switch']">
                        <el-switch
                            @click.native.prevent="changeSyncEnable(activeSyncInfo, 'switch')"
                            :width="42"
                            v-model="activeSyncInfo.syncEnable"
                            active-color="#366AFF"
                            inactive-color="#E2E2E2">
                        </el-switch>
                        <span
                            class="switch-open-icon"
                            @click="changeSyncEnable(activeSyncInfo, 'open')"
                            v-if="activeSyncInfo.syncEnable">
                          <i class="iconfont guoran-a-16-17"></i>
                        </span>
                        <span
                            class="switch-close-icon"
                            @click="changeSyncEnable(activeSyncInfo, 'close')"
                            v-if="!activeSyncInfo.syncEnable">
                          <i class="arsenal_icon arsenalcuo1"></i>
                        </span>
                      </div>
                      <span class="tips-text">{{$t('AISearch.SyncAISearch')}}</span>
                    </div>
                  </div>
                  <div v-if="activeSyncInfo.syncEnable" class="folder-status-config-local">
                    <span class="fscl-title">
                      {{$t('AISearch.aisearchPath')}}
                    </span>
                    <span class="fscl-title-tip">
                      {{$t('AISearch.pathDescription')}}
                    </span>
                    <el-input size="mini" @input="validateInput" :disabled="activeSyncInfo.parentSyncStatus == 1" v-model="activeSyncInfo.syncPath" :placeholder="$t('AISearch.enterPlaceholder')"></el-input>
                    <span v-show="activeSyncInfo.parentSyncStatus == 1" class="fscl-tip">
                      {{$t('AISearch.cannotChangeIfSyncedByName')}}
                    </span>
                  </div>
                  <div v-show="activeSyncInfo.parentSyncStatus != 1" class="folder-status-config-footer">
                    <span @click="changeAsynStatus('cancel')" class="fscf-concel">{{$t('common.cancel')}}</span>
                    <span @click="changeAsynStatus('save')" class="fscf-confirm">{{$t('common.save')}}</span>
                  </div>
                </div>
                <div slot="reference" class="file-status">
                  <span v-if="scope.row.syncStatus == 2" class="file-status-label file-status-synchronization-ing">{{$t('AISearch.InSync')}}</span>
                  <span v-if="scope.row.syncStatus == 0" class="file-status-label file-status-synchronization-no">{{$t('AISearch.SyncNoEnable')}}</span>
                  <span v-if="scope.row.syncStatus == 3" class="file-status-label file-status-synchronization-succ">{{$t('AISearch.SyncSuccess')}}</span>
                  <span v-if="scope.row.syncStatus == 4" class="file-status-label file-status-synchronization-err">{{$t('AISearch.SyncFail')}}</span>
                  <span v-if="scope.row.syncStatus == 5" class="file-status-label file-status-synchronization-no">{{$t('AISearch.Pubsync')}}</span>
                </div>
              </el-popover>
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="fileSuffix" :label="$t('knowledgeManagement.knowledgeTable.fileSuffix')" align="center"
          width="100" :filters="typeList" :filter-multiple="false" :filter-method="filterHandler">
          <template slot-scope="scope">
            <template v-if="scope.row.type == 1">
              <div class="docTypeName" v-if="scope.row.fileSuffix">
                <span>{{ scope.row.fileSuffix.replace('.', "") }}</span>
              </div>
              <template v-else>--</template>
            </template>
            <template v-else>
              <div class="docTypeName">{{ $t('knowledgeManagement.knowledgeTable.folder') }}</div>
            </template>
          </template>
        </el-table-column>
        <el-table-column align="left" :width="$i18n.locale === 'en' ? '150' : '120'"
          :label="$t('knowledgeManagement.knowledgeTable.learnState')" v-if="!synchronizeFiles">
          <template slot-scope="scope">
            <template v-if="scope.row.type == 0">
              --
            </template>
            <template
              v-else-if="scope.row.currentVersion">
              <template v-if="!scope.row.supportLearn">
                <el-tooltip class="item" effect="dark"
                  :content="$t('knowledgeManagement.knowledgeTable.notSupportedLearn')" placement="top">
                  <div style="cursor: pointer;" class="adicic-cell-handle-learning">
                    <span class="achl-image">
                      <i style="color: #a9b3c6;" class="iconfont guoran-zanweixuexi"></i>
                    </span>
                    <span style="color: #a9b3c6;" class="achl-text">{{
                      $t('knowledgeManagement.knowledgeTable.notLearned') }}</span>
                    <span class="achl-tip iconfont guoran-tishi"></span>
                  </div>
                </el-tooltip>
              </template>
              <template v-else>
                <div v-if="scope.row.currentVersion.state === 'ARCHIVE' && scope.row.processVal == 0"
                  class="adicic-cell-handle-learning">
                  <span class="achl-image">
                    <i style="color: #a9b3c6;" class="iconfont guoran-zanweixuexi"></i>
                  </span>
                  <span style="color: #a9b3c6;" class="achl-text">{{ $t('knowledgeManagement.knowledgeTable.notLearned')
                    }}</span>
                </div>
                <div v-else-if="!scope.row.learningFinished && !scope.row.learningFail"
                  class="adicic-cell-handle-learning">
                  <img class="achl-image" src="../../assets/images/loading2.gif" alt="" srcset="">
                  <span class="achl-text">{{ $t('knowledgeManagement.knowledgeTable.learning') }}</span>
                </div>
                <el-tooltip class="item" effect="dark" placement="top-end"
                  :content="$t('knowledgeManagement.knowledgeTable.relearn')" v-else-if="scope.row.learningFail">
                  <div class="adicic-cell-handle-learning" @click.stop="relearn(scope.row)">
                    <i class="iconfont guoran-xuexishibai"></i>
                    <span class="achl-text fail-text">{{ $t('knowledgeManagement.knowledgeTable.learnFail') }}</span>
                  </div>
                </el-tooltip>
                <div v-else-if="scope.row.learningFinished" class="adicic-cell-handle-learning">
                  <span class="achl-image">
                    <i class="iconfont guoran-xuexiwancheng"></i>
                  </span>
                  <span class="achl-text">{{ $t('knowledgeManagement.knowledgeTable.learnFinish') }}</span>
                </div>
              </template>
            </template>
            <template v-else>
              <div style="cursor: pointer;" class="adicic-cell-handle-learning">
                <span class="achl-image">
                  <i style="color: #a9b3c6;" class="iconfont guoran-zanweixuexi"></i>
                </span>
                <span style="color: #a9b3c6;" class="achl-text">{{ $t('knowledgeManagement.knowledgeTable.notLearned')
                  }}</span>
              </div>
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="address" :label="$t('knowledgeManagement.knowledgeTable.updateTime')" align="center"
          width="200">
          <template slot-scope="scope">
            <span class="updateTime">
              <i class="iconfont guoran-a-18-15"></i>
              {{ new Date(scope.row.updateTime).Format('yyyy-MM-dd hh:mm:ss') }}
            </span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" :label="$t('knowledgeManagement.knowledgeTable.operation')" align="center"
          width="90" v-if="!isAskLight()">
          <template slot-scope="scope">
            <span class="operation">
              <div @click.stop="() => { }">
                <el-dropdown @command="handleCommand($event, scope.row)"
                  v-if="synchronizeFiles && scope.row.fileSelfType !== 'other'">
                  <span class="el-dropdown-link">
                    <i class="iconfont guoran-qita1"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown" class="dropdown-menu">
                    <el-dropdown-item :command="'relevance'"
                      v-if="!scope.row.associatedSyncStatus && scope.row.fileSelfType !== 'other'">
                      <i class="iconfont guoran-tongyichicun-shujuguanlian"></i>
                      <span class="text">{{ $t('knowledgeManagement.knowledgeTable.associationSynchronization')
                        }}</span>
                    </el-dropdown-item>
                    <el-dropdown-item :command="'cancelRelevance'" v-if="scope.row.associatedSyncStatus">
                      <i class="iconfont guoran-quxiao"></i>
                      <span class="text">{{ $t('knowledgeManagement.knowledgeTable.cancelAssociation') }}</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-dropdown trigger="click" @command="handleCommand($event, scope.row)"
                  v-else-if="!scope.row.onlySee && scope.row.fileSelfType !== 'other' && (scope.row.hasManagement || roleId == 1)">
                  <span class="el-dropdown-link">
                    <i class="iconfont guoran-qita1"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown" class="dropdown-menu">
                    <!-- v-if="!scope.row.yuQueId && !scope.row.feishuToken" -->
                    <el-dropdown-item :command="'edit'">
                      <i class="iconfont guoran-tongyichicun-16-15-lianjibianji"></i>
                      {{ $t('knowledgeManagement.knowledgeTable.edit') }}
                    </el-dropdown-item>
                    <el-dropdown-item :command="'permission'">
                      <i class="iconfont guoran-tongyichicun-shezhiquanxian"></i>
                      {{ $t('knowledgeManagement.knowledgeTable.setPermission') }}
                    </el-dropdown-item>
                    <el-dropdown-item :command="'synchronization'" v-if="scope.row.associatedSyncStatus">
                      <i class="iconfont guoran-tongyichicun-shujuguanlian"></i>
                      {{ $t('knowledgeManagement.knowledgeTable.synchronization') }}
                    </el-dropdown-item>
                    <el-dropdown-item :command="'shift'">
                      <i class="iconfont guoran-tongyichicun-yidong"></i>
                      {{ $t('knowledgeManagement.knowledgeTable.shift') }}
                    </el-dropdown-item>
                    <el-dropdown-item :command="'del'">
                      <i class="iconfont guoran-piliangshanchu"></i>
                      {{ $t('knowledgeManagement.knowledgeTable.del') }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </span>
          </template>
        </el-table-column>
      </el-table>
      <pagination :pageSize="pageSize" :currentPage="currentPage" :total="tableTotal" v-if="tableData.length > 0"
        @currentChange="handleCurrentChange"></pagination>
      <div class="selectData-box" v-show="showTags">
        <div class="selectData">
          <div class="tagItem" @click="handleCheckAllChange">
            {{ $t('knowledgeManagement.knowledgeTable.allSelected') }}
          </div>
          <div class="tagItem" @click="batchSetAuthority">{{ $t('knowledgeManagement.knowledgeTable.setPermission') }}
          </div>
          <template v-if="synchronizeFiles">
            <div class="tagItem" @click="associated('associated')" v-if="associatedSync">
              {{ $t('knowledgeManagement.knowledgeTable.associationSynchronization') }}
            </div>
            <div class="tagItem" @click="associated('cancel')" v-if="!associatedSync">
              {{ $t('knowledgeManagement.knowledgeTable.cancelAssociation') }}
            </div>
          </template>
          <template v-else>
            <div class="tagItem" @click="moveFolder">
              {{ $t('knowledgeManagement.knowledgeTable.shift') }}
            </div>
            <div class="tagItem" @click="deleteFolder">
              {{ $t('knowledgeManagement.knowledgeTable.del') }}
            </div>
          </template>
          <div class="tagItem" v-if="noFolder" @click="batchPublish">
            {{ $t('knowledgeManagement.newsAnnouncement.publish') }}
          </div>
          <div class="tagItem" v-if="noFolder" @click="batchUnPublish">
            {{ $t('knowledgeManagement.newsAnnouncement.unPublish') }}

          </div>
          <div class="tagItem" v-if="noFolder" @click="batchDocumentation">
            {{ $t('knowledgeManagement.newsAnnouncement.documentation') }}

          </div>
          <div class="tagItem" v-if="noFolder" @click="batchCancelDocumentation">
            {{ $t('knowledgeManagement.newsAnnouncement.cancelDocumentation') }}

          </div>
          <div class="tagItem" @click="batchHandleSync(true)">
            {{ $t('AISearch.EnableSync') }}
          </div>
          <div class="tagItem" @click="batchHandleSync(false)">
            {{ $t('AISearch.OffSync') }}
          </div>
          <div class="tagItem" @click="cancelChecked">
            {{ $t('knowledgeManagement.knowledgeTable.cancelSelected') }}
            <span class="checkTotal">
              {{ selection.length }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="emptyState">
      <el-empty>
        <div slot="default">
          <img src="../../assets/images/empty_list.png" style="width: 400px;">
        </div>
        <template v-if="!synchronizeFiles">
          <div v-if="isAskLight() && haveKnowledgePermission && haveKnowledgePermission == 'true'"
            class="synchronousUpload">
            <div class="buttonItem" @click="goManage">
              <span>{{ $t('knowledgeManagement.knowledgeTable.manageKnowledge') }}</span>
            </div>
          </div>
          <template
            v-else-if="currentNode.parentId == -1 && currentNode.children && currentNode.children.length == 0 && !isAskLight()">
            <div class="tips">
              <div class="tips-1">
                {{ $t('knowledgeManagement.knowledgeTable.emptyKnowledgeTips1') }}
              </div>
              <div class="tips-2">
                {{ $t('knowledgeManagement.knowledgeTable.emptyKnowledgeTips2') }}
              </div>
            </div>
            <div class="synchronousUpload">
              <div class="buttonItem" @click="openDirectoryDialog">
                <span>{{ $t('knowledgeManagement.knowledgeTable.folderManagementTitle') }}</span>
              </div>
              <div class="buttonItem" @click="syncThird" v-if="channelList.length < 2">
                <span>{{ $t('knowledgeManagement.knowledgeTable.syncFolder') }}</span>
              </div>
              <el-dropdown v-else trigger="hover" class="dropdown-channel-list" @command="handleCommandChooseChannel">
                <div class="buttonItem">
                  <span>{{ $t('knowledgeManagement.knowledgeTable.syncFolder') }}</span><i
                    class="el-icon-arrow-down el-icon--right"></i>
                </div>
                <el-dropdown-menu slot="dropdown" class="dropdown-channel-menu">
                  <el-dropdown-item v-for="(item, index) in channelList" :key="index" :command="item.channel">
                    <img :src="item.channelImg" alt="">
                    <span>{{ item.channelName }}</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
          <template v-else>
            <div class="tips">
              {{ $t('knowledgeManagement.knowledgeTable.emptyKnowledge') }}
            </div>
          </template>
        </template>
      </el-empty>
    </div>

    <popup-com @closeEvent="cancelSync" v-show="associatedDialog" class="choose-popup">
      <div slot="popup-name">{{ $t('knowledgeManagement.knowledgeTable.syncPopupTitle') }}</div>
      <div slot="popup-tip">{{ $t('knowledgeManagement.knowledgeTable.syncPopupTip') }}</div>
      <div slot="popup-con-c" class="popupAssociated">
        <div class="title">
          {{ $t('knowledgeManagement.knowledgeTable.syncSelected') }}
          <span class="numTag">{{ selection.length }}</span>
        </div>
        <div class="associatedContainer">
          <associated-tree @chooseFolder="chooseFolder" :checkFolder="selection" ref="associatedTree"
            @removeFolder="removeFolder" v-if="associatedTree" :channel="channel"></associated-tree>
        </div>
      </div>
      <div slot="dialog-footer">
        <el-button plain size="small" @click="cancelSync" class="cancel-btn">{{
          $t('knowledgeManagement.knowledgeTable.segPopupCancel') }}</el-button>
        <el-button type="primary" size="small" @click="starSynchronization" class="confirm-btn">{{
          $t('knowledgeManagement.knowledgeTable.syncStart') }}</el-button>
      </div>
    </popup-com>
    <el-drawer :with-header="false" :visible.sync="drawerDoc" size="800px" direction="rtl" :destroy-on-close="true"
      :append-to-body="true" :wrapperClosable="false">
      <div class="pre_title">
        <div class="close" @click="drawerDoc = false">
          <i class="iconfont guoran-tongyichicun-guanbi1"></i>
        </div>
        <div class="title">
          <template>
            {{ knowledgeItem.name }}
          </template>
        </div>
        <div class="title_right">
          <el-select v-model="segmentationValue" :placeholder="$t('knowledgeManagement.knowledgeTable.placeholder')"
            @change="changeSegementation" size="small">
            <el-option value="DEFAULT" :label="$t('knowledgeManagement.knowledgeTable.segPopupDefault')"></el-option>
            <el-option value="PAGE" :label="$t('knowledgeManagement.knowledgeTable.segPopupPage')"></el-option>
            <el-option value="NONE" :label="$t('knowledgeManagement.knowledgeTable.segPopupNone')"></el-option>
          </el-select>
          <i class="iconfont guoran-tongyichicun-biaozhu" :style="{ color: markFlag ? '#366aff' : '#000000' }"
            v-if="$route.query && $route.query.withLocationMark" @click="openMark()"></i>
        </div>
      </div>
      <div class="pre_cont">
        <FilePreview ref="filePreview" :url="previewHref" :fileType="previewFileType" v-if="drawerDoc">
        </FilePreview>
      </div>
    </el-drawer>
    <delete-dialog @closeEvent="deleteFileDialog = false" v-if="deleteFileDialog">
      <div slot="popup-container" class="popup-container">
        <div class="delete-title">{{ $t('knowledgeManagement.knowledgeTable.popupContainerTitle') }}</div>
        <div class="prompt-text">
          {{ $t('knowledgeManagement.knowledgeTable.popupContainerText') }}
        </div>
      </div>
      <div slot="dialog-footer">
        <el-button @click="deleteFileDialog = false" size="small" plain class="cancel-btn">{{
          $t('knowledgeManagement.knowledgeTable.segPopupCancel') }}</el-button>
        <el-button @click="deleteFile" style="background-color: #FE5965 ;color: white;border:none;" size="small"
          class="confirm-btn">{{ $t('knowledgeManagement.knowledgeTable.del') }}
        </el-button>
      </div>
    </delete-dialog>

    <popup-com @closeEvent="notBindPopup = false" v-if="notBindPopup">
      <div slot="popup-name">{{ $t('knowledgeManagement.knowledgeTable.bindPopupTitle') }}</div>
      <div slot="popup-tip">{{ $t('knowledgeManagement.knowledgeTable.bindPopupTip') }}</div>
      <div slot="dialog-footer">
        <el-button plain size="small" @click="notBindPopup = false" class="cancel-btn">{{
          $t('knowledgeManagement.knowledgeTable.segPopupCancel') }}</el-button>
        <el-button type="primary" size="small" @click="goBind" class="confirm-btn">{{
          $t('knowledgeManagement.knowledgeTable.bindPopupConfirm') }}</el-button>
      </div>
    </popup-com>
    <employee-structure ref="employeeStructure" :permissionList="permissionList" @confirmPermission="confirmPermission"
      @cancelPermission="cancelPermission" v-if="false" @onNodeClick="onNodeClick"></employee-structure>
    <knowledge-permissions ref="knowledgePermissions" v-if="permissionDialog"
      :class="synchronizeFiles ? 'synchronizeDialog' : ''"
      @confirmKnowledgePermissions="confirmPermission"></knowledge-permissions>
    <popup-com class="segmentation_popup" @closeEvent="segmentationVisible = false" v-if="segmentationVisible">
      <div slot="popup-name">{{ $t('knowledgeManagement.knowledgeTable.segPopupTitle') }}</div>
      <div slot="popup-con">{{ $t('knowledgeManagement.knowledgeTable.segPopupContent') }}
        <span>
          {{ segmentationValue == 'DEFAULT' ? $t('knowledgeManagement.knowledgeTable.segPopupDefault') :
            (segmentationValue == 'PAGE' ? $t('knowledgeManagement.knowledgeTable.segPopupPage') :
              $t('knowledgeManagement.knowledgeTable.segPopupNone'))
          }}
        </span>
        ?
      </div>
      <div slot="dialog-footer">
        <el-button plain size="small" @click="segmentationVisible = false" class="cancel-btn">{{
          $t('knowledgeManagement.knowledgeTable.segPopupCancel') }}</el-button>
        <el-button type="primary" size="small" @click="sureSegmentation" class="confirm-btn">{{
          $t('knowledgeManagement.knowledgeTable.segPopupConfirm') }}</el-button>
      </div>
    </popup-com>
    <!-- @closeEvent="segmentationVisible = false" -->
    <PopupCom class="check_permission_popup" v-if="checkPermissionVisible"  @closeEvent="checkPermissionVisible = false">
      <div slot="popup-name">{{ $t("knowledgeManagement.knowledgeTable.checkPermission") }}</div>
      <div slot="popup-con">
        <div class="check_permission_content">
          <span class="title">{{ $t("knowledgeManagement.knowledgeTable.noPermissionTips") }}</span>
          <p v-for="item in notHasPermissionList" :key="item.id">
            <template v-if="item.type == 1">
              <img src="../../assets/images/video.png" v-if="setSrc(item.fileSuffix) == 'video'" />
              <img src="../../assets/images/img.png" v-else-if="setSrc(item.fileSuffix) == 'image'" />
              <img src="../../assets/images/txt.png" v-else-if="setSrc(item.fileSuffix) == 'txt'" />
              <img src="../../assets/images/doc.png"
                v-else-if="setSrc(item.fileSuffix) == 'word' || (item.fileSuffix && item.fileSuffix.includes('文档') && item.type == 1)" />
              <img src="../../assets/images/pdf.png" v-else-if="setSrc(item.fileSuffix) == 'pdf'" />
              <img src="../../assets/images/ppt.png" v-else-if="setSrc(item.fileSuffix) == 'ppt'" />
              <img src="../../assets/images/xls.png" v-else-if="setSrc(item.fileSuffix) == 'excel'" />
              <img src="../../assets/images/html.png" v-else-if="setSrc(item.fileSuffix) == 'html'" />
              <img src="../../assets/images/mp3.png" v-else-if="setSrc(item.fileSuffix) == 'audio'" />
              <img src="../../assets/images/other.png" v-else>
            </template>
            <img v-if="item.type == 0" src="../../assets/images/file.png" class="folder-icon">
            <span class="docName">{{ item.name }}</span>
          </p>
        </div>

      </div>
      <div slot="dialog-footer">
        <!-- <el-button plain size="small" @click="checkPermissionVisible = false" class="cancel-btn">{{
          $t('knowledgeManagement.knowledgeTable.segPopupCancel') }}</el-button> -->
        <el-button type="primary" size="small" @click="ImSure" class="confirm-btn">
          {{ $t('common.gotIt') }}
        </el-button>
      </div>
    </PopupCom>

    <PopupCom class="batch_approve_popup" v-if="needApprovalVisible" @closeEvent="needApprovalVisible = false">
      <div slot="popup-name">{{ $t("knowledgeManagement.knowledgeTable.needApprovalTitle") }}</div>
      <div slot="popup-con">
        <div class="check_permission_content">
          <span class="title">
            {{ switchOperateType() }}，
            {{ $t("knowledgeManagement.knowledgeTable.needApproval") }}
          </span>
          <p v-for="item in needApprovalKnowledge" :key="item.id">
            <template v-if="item.type == 1">
              <img src="../../assets/images/video.png" v-if="setSrc(item.fileSuffix) == 'video'" />
              <img src="../../assets/images/img.png" v-else-if="setSrc(item.fileSuffix) == 'image'" />
              <img src="../../assets/images/txt.png" v-else-if="setSrc(item.fileSuffix) == 'txt'" />
              <img src="../../assets/images/doc.png"
                v-else-if="setSrc(item.fileSuffix) == 'word' || (item.fileSuffix && item.fileSuffix.includes('文档') && item.type == 1)" />
              <img src="../../assets/images/pdf.png" v-else-if="setSrc(item.fileSuffix) == 'pdf'" />
              <img src="../../assets/images/ppt.png" v-else-if="setSrc(item.fileSuffix) == 'ppt'" />
              <img src="../../assets/images/xls.png" v-else-if="setSrc(item.fileSuffix) == 'excel'" />
              <img src="../../assets/images/html.png" v-else-if="setSrc(item.fileSuffix) == 'html'" />
              <img src="../../assets/images/mp3.png" v-else-if="setSrc(item.fileSuffix) == 'audio'" />
              <img src="../../assets/images/other.png" v-else>
            </template>
            <img v-if="item.type == 0" src="../../assets/images/file.png" class="folder-icon">
            <span class="docName">{{ item.name }}</span>
          </p>
        </div>

      </div>
      <div slot="dialog-footer">
        <!-- <el-button plain size="small" @click="checkPermissionVisible = false" class="cancel-btn">{{
          $t('knowledgeManagement.knowledgeTable.segPopupCancel') }}</el-button> -->
        <el-button type="primary" size="small" @click="needApprovalVisible = false" class="confirm-btn">
          {{ $t('common.gotIt') }}
        </el-button>
      </div>
    </PopupCom>
    <PopupCom class="batch_approve_popup" v-if="needSyncVisible" @closeEvent="needSyncVisible = false">
      <div slot="popup-name">批量启用同步</div>
      <div slot="popup-tip">可进行批量启用同步或变更已有同步路径</div>
      <div slot="popup-con">
        <div class="sync-batch-enable">
          <div class="sync-batch-enable-header">
            <span class="sync-batch-enable-header-name">名称</span>
            <span class="sync-batch-enable-header-local">
              AISearch路径
              <span class="sync-batch-enable-header-local-require">*</span>
            </span>
            <span class="sync-batch-enable-header-handle"></span>
          </div>
          <div class="sync-batch-enable-list">
            <div v-for="(item, itemIndex) in syncSelection" :key="itemIndex" class="sync-batch-enable-list-cell">
              <div class="sbel-name">
                <template v-if="item.type == 1">
                  <img src="../../assets/images/video.png" v-if="setSrc(item.fileSuffix) == 'video'" />
                  <img src="../../assets/images/img.png" v-else-if="setSrc(item.fileSuffix) == 'image'" />
                  <img src="../../assets/images/txt.png" v-else-if="setSrc(item.fileSuffix) == 'txt'" />
                  <img src="../../assets/images/doc.png"
                    v-else-if="setSrc(item.fileSuffix) == 'word' || (item.fileSuffix && item.fileSuffix.includes('文档') && item.type == 1)" />
                  <img src="../../assets/images/pdf.png" v-else-if="setSrc(item.fileSuffix) == 'pdf'" />
                  <img src="../../assets/images/ppt.png" v-else-if="setSrc(item.fileSuffix) == 'ppt'" />
                  <img src="../../assets/images/xls.png" v-else-if="setSrc(item.fileSuffix) == 'excel'" />
                  <img src="../../assets/images/html.png" v-else-if="setSrc(item.fileSuffix) == 'html'" />
                  <img src="../../assets/images/mp3.png" v-else-if="setSrc(item.fileSuffix) == 'audio'" />
                  <img src="../../assets/images/other.png" v-else>
                </template>
                <img v-if="item.type == 0" src="../../assets/images/file.png" class="folder-icon">
                <span class="docName">{{ item.name }}</span>
              </div>
              <div class="sbel-path">
                <span class="sbel-path-tip-change" v-if="item.parentSyncStatus == 1" @click="tipChange">{{item.syncPath}}</span>
                <el-input v-else v-model="item.syncPath" @input="validateInputBatch($event, itemIndex)" :disabled="item.parentSyncStatus == 1" size="small"></el-input>
              </div>
              <div @click="removeSyncSelect(itemIndex)" class="sbel-handle">
                <span class="iconfont guoran-tongyichicun-16-09-shanchu2"></span>
              </div>
            </div>
          </div>
          <div class="sync-batch-enable-tip">
            如目录下有知识已经同步到AISearch中，则同步时将会以知识所属目录的AISearch路径为准。
          </div>
        </div>
      </div>
      <div slot="dialog-footer">
        <el-button plain size="small" @click="needSyncVisible = false" class="cancel-btn">{{
          $t('common.cancel') }}</el-button>
        <el-button type="primary" size="small" @click="batchSyncSave" class="confirm-btn">
          {{ $t('common.confirm') }}
        </el-button>
      </div>
    </PopupCom>
    <delete-dialog @closeEvent="closeSyncDialog = false" v-if="closeSyncDialog">
      <div slot="popup-container" class="popup-container">
        <div class="delete-title">批量关闭同步</div>
        <div v-if="hasParentSync" class="prompt-text">
          所选目录或知识，部分已通过目录名称同步，无法关闭同步，如若关闭，请先关闭父目录同步。
        </div>
        <div v-else class="prompt-text">
          确认关闭同步？
        </div>
      </div>
      <div slot="dialog-footer">
        <el-button @click="closeSyncDialog = false" style="background-color: #FE5965 ;color: white;border:none;" size="small"
          class="confirm-btn">确 认
        </el-button>
      </div>
    </delete-dialog>
  </div>
</template>

<script>
import Pagination from "@/components/pagination";
import AssociatedTree from "@/views/knowledgeManagement/associatedTrees.vue";
import PopupCom from "@/components/popup";
import FilePreview from "@/components/FilePreview";
import EmployeeStructure from "@/components/employeeStructure";
import { requestUrl } from "@/http/requestUrl";
import { RequiredVerification } from "@/assets/js/filterTree";
import DeleteDialog from "@/components/deleteDialog";
import { parentNode } from "@/assets/js/getParentNode";
import { handlerChannel, handlerChannelImg } from '@/utils/tools.js'
import { redirectUri } from "@/http/redirectUri";
import { GetQueryString } from "@/assets/js/filterTree";
import KnowledgePermissions from "@/components/knowledgePermissions";
var that
export default {
  name: "knowledgeTable",
  data() {
    return {
      tableData: [],
      typeList: [
        {
          text: "语雀文档",
          value: '语雀文档'
        },
        {
          text: "图片",
          value: '.image'
        },
        {
          text: "视频",
          value: '.video'
        },
        {
          text: "文档",
          value: '.word'
        },
        {
          text: "幻灯片",
          value: '.ppt'
        },
        {
          text: "表格",
          value: '.excel'
        },
        {
          text: "TXT",
          value: 'txt'
        },
        {
          text: "PDF",
          value: 'pdf'
        },
      ],
      dropDownIcon: "el-icon-arrow-down",
      pageSize: 20,
      currentPage: 1,
      tableTotal: 0,
      hasNextPage: false,
      isCheck: false,
      indeterminate: false,
      selection: [],
      selectionCopy: [],
      selectDataList: [],
      options: {

      },
      associatedDialog: false,
      associatedTree: false,
      showTags: false,
      folderId: "",
      checkNewData: [],
      drawerDoc: false,
      checkFolderCopy: {},
      knowledgeItem: {},
      previewHref: "",
      previewFileType: '',
      moveToNew: false,
      parentId: "",
      deleteFileDialog: false,
      deleteRow: {},
      aiLearningTimer: null,
      loading: false,
      markFlag: false,
      channelList: [],
      notBindPopup: false,
      isClickOperationRelevance: false, // 是否是点击表格中'操作'一栏触发的关联同步
      haveKnowledgePermission: false, // 是否有企业知识管理权限
      permissionList: [],
      permissionDialog: false,
      activeRow: {},
      segmentationValue: 'DEFAULT',
      segmentationVisible: false,
      roleId: 0,
      saveLoading: false,
      notHasPermissionList: false,
      checkPermissionVisible: false,
      nextExecuteFnName: "",
      needApprovalVisible: false,
      needApprovalKnowledge: [],
      batchOperateType: "",
      noFolder: true,

      // 立邦定制同步AISEARCH
      changeAsynStatusFooterVisible: true,
      activeSyncInfo: {},
      syncSelection: [],
      needSyncVisible: false,
      syncBatchEnable: false,
      closeSyncDialog: false,
      hasParentSync: false
    }
  },
  props: ["currentNode", "synchronizeFiles", "breadcrumb", "channel", "synchronizationDrawer"],
  computed: {
    associatedSync() {
      let bool = false
      let associatedSyncStatus = this.selection.map(res => {
        return res.associatedSyncStatus
      })
      if (associatedSyncStatus.indexOf(true) != -1) {
        bool = false
      } else {
        bool = true
      }
      return bool
    },
  },
  filters: {
    statusFilter(val) {
      if (!val || val === 'UNPUBLISHED') {
        return that.$t('status.unPublish')
      } else if (val === 'PUBLISH' || val === 'PUBLISHING') {
        return that.$t('status.publish')
      } else {
        return that.$t('status.documentation')
      }
    }
  },
  components: { KnowledgePermissions, DeleteDialog, PopupCom, AssociatedTree, Pagination, FilePreview, EmployeeStructure },
  methods: {
    tipChange(){
      this.$message({
        message: "已通过目录名称同步，无法变更同步或路径，如若变更，请先关闭父目录同步。",
        type: 'warning',
        duration: 5000
      })
    },
    showFolderStatus(row){
      this.activeSyncInfo = JSON.parse(JSON.stringify(row));
      this.$forceUpdate();
    },
    hideFolderStatus(){
      this.activeSyncInfo = {
        syncEnable: false,
      };
      this.$forceUpdate();
    },
    synchronizationAll(){
      if (new Date().getTime() - this.synchronizationAllTime < 5*60*1000 && this.synchronizationAllTime != 0) {
        this.$message({
          message: "一键同步操作过于频繁，请稍后再试",
          type: 'warning',
          duration: 5000
        })
        return;
      }
      this.$http.post(requestUrl.AISearch.allBatchExecute).then(res => {
        if (res.data.code == '0') {
          this.synchronizationAllTime = new Date().getTime();
          this.$message({
            message: "开始同步",
            duration: 2000,
            type:"success"
          })
        } else {
          this.$message({
            message: res.data.msg,
            type: 'warning',
            duration: 2000
          })
        }
      })
    },
    changeAsynStatus(handleType){
      
      if (handleType == 'save') {
        if (!this.activeSyncInfo.syncPath && this.activeSyncInfo.syncEnable) {
          this.$message({
            message: "AISearch路径不能为空",
            type: 'warning',
            duration: 5000
          })
          return;
        }
        this.$http.post(requestUrl.AISearch.syncEnable, {
          id: this.activeSyncInfo.id,
          syncEnable: this.activeSyncInfo.syncEnable,
          syncPath: this.activeSyncInfo.syncPath
        }).then(res => {
          if (res.data.code == '0') {
            this.$message({
              message: "设置成功",
              duration: 2000,
              type:"success"
            })
            this.changeAsynStatusFooterVisible = false;
            setTimeout(() => {
              this.changeAsynStatusFooterVisible = true;
            }, 800);
            let activeData = this.tableData.find(item => item.id == this.activeSyncInfo.id);
            if (activeData.type == 0) {
              activeData.syncEnable = this.activeSyncInfo.syncEnable;
              activeData.syncPath = this.activeSyncInfo.syncPath;
              activeData.syncStatus = this.activeSyncInfo.syncEnable?1:0;
            } else {
              activeData.syncEnable = this.activeSyncInfo.syncEnable;
              activeData.syncPath = this.activeSyncInfo.syncPath;
              if (activeData.syncEnable) {
                if (activeData.currentVersion.state === 'UNPUBLISHED') {
                  activeData.syncStatus = 5;
                } else {
                  activeData.syncStatus = 2;
                }
                
              } else {
                activeData.syncStatus = 0;
              }
            }
          } else {
            this.$message({
              message: res.data.msg,
              type: 'warning',
              duration: 5000
            })
          }
        })
      } else {
        this.changeAsynStatusFooterVisible = false;
        setTimeout(() => {
          this.changeAsynStatusFooterVisible = true;
        }, 800);
      }
    },
    changeSyncEnable(data, type){
      console.log(801, type, this.activeSyncInfo.syncEnable);
      if (this.activeSyncInfo.parentSyncStatus == 1) {
        // 兼容原element点击事件
        if (type == 'switch') {
          this.activeSyncInfo.syncEnable = !this.activeSyncInfo.syncEnable;
        }
        if (this.activeSyncInfo.syncStatus == 4) {
          this.$message({
            message: "请点击同步按钮重试同步。",
            type: 'warning',
            duration: 5000
          })
        } else {
          this.$message({
            message: "已通过目录名称同步，无法变更同步或路径，如若变更，请先关闭父目录同步",
            type: 'warning',
            duration: 5000
          })
        }
       
      } else {
        if (this.activeSyncInfo.syncEnable == undefined) {
          console.log(785, type, this.activeSyncInfo.syncEnable);
          this.$set(this.activeSyncInfo,'syncEnable', true);
        } else {
          if (type != 'switch') {
            this.activeSyncInfo.syncEnable = !this.activeSyncInfo.syncEnable;
          }
        }
      }
    },
    reSyncExecute(){
      this.$http.post(requestUrl.AISearch.aisearchSyncExecute, {
        id: this.activeSyncInfo.id,
        syncEnable: true,
        syncPath: this.activeSyncInfo.syncPath
      }).then(res => {
        if (res.data.code == '0') {
          this.$message({
            message: "开始执行同步",
            duration: 2000,
            type:"success"
          })
          let activeData = this.tableData.find(item => item.id == this.activeSyncInfo.id);
          activeData.syncStatus = 2;
        } else {
          this.$message({
            message: res.data.msg,
            type: 'warning',
            duration: 2000
          })
        }
      })
    },
    batchHandleSync(status){
      this.syncSelection = JSON.parse(JSON.stringify(this.selection));
      this.syncBatchEnable = status;
      if (status) {
        this.needSyncVisible = true;
      } else {
        this.hasParentSync = this.syncSelection.some(item => item.parentSyncStatus == 1);
        this.closeSyncDialog = true;
      }
      
    },
    batchSyncSave(){
      const syncEnableInfos = this.syncSelection.map(item => ({
        id: item.id,
        syncPath: item.syncPath
      }));
      const hasEmptyPath = syncEnableInfos.some(item => (item.syncPath === '' || item.syncPath === null));
      
      if (this.syncBatchEnable && hasEmptyPath) {
        this.$message({
          message: "AISearch路径不能为空",
          type: 'warning',
          duration: 2000
        })
        return;
      }
      let hasEnableSyncIds = [];
      this.$http.post(requestUrl.AISearch.syncBatchEnable, {
        syncEnable: this.syncBatchEnable,
        syncEnableInfos: syncEnableInfos
      }).then(res => {
        if (res.data.code == '0') {
          this.$message({
            message: "设置成功",
            duration: 2000,
            type:"success"
          })
          this.needSyncVisible = false;
          this.closeSyncDialog = false;
        } else {
          hasEnableSyncIds = res.data.data.hasEnableSyncIds??[];
          this.$message({
            message: res.data.msg,
            type: 'warning',
            duration: 2000
          })
          this.needSyncVisible = false;
          this.closeSyncDialog = false;
        }
        // 由于接口在不完全可同步会返回非0code 因此暂做此处理
        if (this.syncBatchEnable) {
          let index = -1;
          syncEnableInfos.forEach(cell => {
            index = this.tableData.findIndex(item => item.id == cell.id);
            if (
              hasEnableSyncIds.indexOf(this.tableData[index].id) == -1
            ) {
              if (this.tableData[index].type == 0) {
                this.tableData[index].syncEnable = true;
                this.tableData[index].syncPath = cell.syncPath;
                this.tableData[index].syncStatus = 1;
              } else {
                this.tableData[index].syncEnable = true;
                this.tableData[index].syncPath = cell.syncPath;
                if (this.tableData[index].currentVersion.state === 'UNPUBLISHED') {
                  this.tableData[index].syncStatus = 5;
                } else {
                  this.tableData[index].syncStatus = 2;
                }
              }
            }
            
          })
          this.tableData = [...this.tableData];
        } else {
          let index = -1;
          syncEnableInfos.forEach(cell => {
            index = this.tableData.findIndex(item => item.id == cell.id);
            if (
              hasEnableSyncIds.indexOf(this.tableData[index].id) == -1
            ) {
              this.tableData[index].syncEnable = false;
              this.tableData[index].syncStatus = 0;
            }
            
          })
          this.tableData = [...this.tableData];
        }
        this.$forceUpdate();
      })
    },
    removeSyncSelect(itemIndex){
      this.syncSelection.splice(itemIndex, 1);
    },
    changeSearch(type){
     console.log(664, type);
    },
    // 路径输入框输入值校验
    validateInput(event) {
      const input = event;
      const regex = /^[a-zA-Z0-9-_]*$/; // 正则表达式，允许大小写字母、数字、下划线和连字符
      if (!regex.test(input)) {
        // 如果输入不匹配正则表达式，则恢复到上一次的有效值
        this.$set(this.activeSyncInfo, 'syncPath', this.lastValidInput || '');
      } else {
        // 如果输入有效，则更新 lastValidInput
        this.lastValidInput = input;
      }
    },
    validateInputBatch($event, itemIndex){
      const input = $event;
      const regex = /^[a-zA-Z0-9-_]*$/; // 正则表达式，允许大小写字母、数字、下划线和连字符
      if (!regex.test(input)) {
        // 如果输入不匹配正则表达式，则恢复到上一次的有效值
        this.$set(this.syncSelection[itemIndex], "syncPath", this.lastValidInput || '');
      } else {
        // 如果输入有效，则更新 lastValidInput
        this.lastValidInput = input;
      }
    },
    setSrc(type) {
      if (type === 'mp4' || type === 'MP4' || type === 'mov' || type === 'MOV' || type == 'avi' || type == 'flv') {
        return 'video'
      } else if (type === 'jpg' || type === 'png' || type === 'JPG' || type === 'PNG' ||
        type === 'jpeg' || type === 'JPEG' || type === 'gif' || type === 'bmp' ||
        type === 'BMP' || type === 'WebP' || type === 'webp' || type === 'svg' || type === 'SVG' || type === 'rgb' || type === 'heic') {
        return 'image'
      } else if (type === 'doc' || type === 'docx' || (type && type.includes('文档'))) {
        return 'word'
      } else if (type === 'pdf') {
        return 'pdf'
      } else if (type === 'ppt' || type === 'pptx') {
        return 'ppt'
      } else if (type === 'xlsx' || type === 'xls') {
        return 'excel'
      } else if (type === 'txt') {
        return 'txt'
      } else if (type === 'mp3' || type === 'MP3') {
        return 'audio'
      } else {
        return type
      }
    },
    handleCommand(command, row) {
      console.log(1063, command, row);
      if (command ==='shift' && row.syncEnable) {
        this.$message({
          message: this.$t('AISearch.cannotBeMoved'),
          duration: 3000,
          type: "warning"
        })
        return;
      }
      this.rowClick(row)
      setTimeout(() => {
        if (command === 'relevance') {
          this.isClickOperationRelevance = true;
          row.children = [];
          if (row.type == 0) {
            row.hasChildren = true;
          }
          if (!row.visitPermissionList) {
            this.$set(row, 'visitPermissionList', [])
          }
          if (!row.parentDirectoryName) {
            this.$set(row, 'parentDirectoryName', "")
          }
          this.selection = [row];
          this.selectionCopy = JSON.stringify([row]);
          // this.$refs.knowledgeTable.toggleRowSelection(row,true);
          this.associatedDialog = true;
          this.associatedTree = true;
        }
        if (command === 'cancelRelevance') {
          if (this.channel === 5) {
            this.cancelRelevanceFolder([row.token]);
          } else {
            this.cancelRelevanceFolder([row.id]);
          }
        }
        if (command === 'edit') {
          this.$emit('editFolder', row)
        }
        if (command === 'shift') {
          this.$emit('moveTo', row)
        }
        if (command === 'del') {
          if (row.type == 0) {
            this.$emit('delFolder', row)
          } else {
            this.deleteFileDialog = true;
            this.deleteRow = row;
          }
        }
        if (command === 'permission') {
          this.$emit('folderPermission', row)
        }
        if (command === 'synchronization') {
          let mainId = sessionStorage.getItem('_mainId');
          let url = ''
          // 5 语雀，6 飞书
          if (row.source === 5) {
            url = requestUrl.channel.autoSyncYuQue + '?mainId=' + mainId;
          } else {
            url = requestUrl.feishu.syncFeishu + '?mainId=' + mainId;
          }
          //  url = requestUrl.knowledgeBase.fileSync + '?id=' + row.id;
          this.$http.get(url).then(res => {
            if (res.data.code == '0') {
              this.$emit('synchronization', row)
              // this.$message({
              //   message:"同步成功",
              //   duration:2000,
              //   type:"success"
              // })
            } else {
              this.$message({
                message: res.data.msg,
                type: 'warning',
                duration: 2000
              })
            }
          })
        }
      })

    },
    deleteFile() {
      let url = requestUrl.knowledgeBase.moveDeleteFolder
      let obj = {
        deleteNodeIds: [this.deleteRow.id],
        newParentId: null
      }
      this.$http.delete(url, {
        data: obj
      }).then(res => {
        if (res.data.code == '0') {
          this.$message({
            message: this.$t('knowledgeManagement.knowledgeTable.deleteSuccess'),
            duration: 2000,
            type: "success"
          })
          this.deleteFileDialog = false;
          if (this.tableData.length == 1) {
            this.currentPage = 1;
          }
          let node = parentNode(this.breadcrumb)
          this.getFolderAndFile(node.id, 'del');
        }
      })
    },
    filterHandler(value, row, column) {
      const property = column['property'];
      let bool = true;
      console.debug('value', value, row[property])
      if (value == '.image') {
        bool = row[property] == 'jpg' || row[property] == 'png' || row[property] == 'JPG' ||
          row[property] == 'PNG' || row[property] == 'jpeg' || row[property] == 'JPEG' ||
          row[property] == 'gif' || row[property] == 'bmp' || row[property] === 'BMP' || row[property] === 'WebP' ||
          row[property] === 'webp' || row[property] === 'svg' || row[property] === 'SVG'
      } else if (value == '.word') {
        bool = row[property] == 'doc' || row[property] == 'docx' || row[property].includes('文档')
      } else if (value == '.ppt') {
        bool = row[property] == 'pptx' || row[property] == 'ppt'
      } else if (value == '.video') {
        bool = row[property] == 'mp4' || row[property] == 'MP4' || row[property] == 'mov' || row[property] == 'MOV' || row[property] == 'avi' || row[property] == 'flv'
      } else if (value == '.excel') {
        bool = row[property] == 'xlsx' || row[property] == 'xls' || row[property].includes('表格')
      } else {
        bool = row[property] === value
      }
      return bool;
    },
    //表格选择数据
    handleSelectionChange(val) {
      val.map(res => {
        res.children = [];
        if (res.type == 0) {
          res.hasChildren = true;
        }
        if (!res.visitPermissionList) {
          this.$set(res, 'visitPermissionList', [])
        }
        if (!res.parentDirectoryName) {
          this.$set(res, 'parentDirectoryName', "")
        }
        return res;
      })
      this.selection = val;
      this.selectionCopy = JSON.stringify(val);
      if (val && val.length > 0) {
        this.showTags = true;
        if (val.length === 1 && this.isClickOperationRelevance) {
          this.showTags = false;
        }
        this.noFolder = !val.some(item => item.type == 0);
      } else {
        this.showTags = false;
        this.noFolder = true;
      }

    },
    handleCheckAllChange() {
      if (this.hasNextPage) {
        if (this.selection.length != 20) {
          this.$refs.knowledgeTable.toggleAllSelection();
        }
      } else {
        if (this.selection.length != this.tableTotal) {
          this.$refs.knowledgeTable.toggleAllSelection();
        }
      }
    },
    parseMarkdown(text) {
      const lines = text.split('\n');
      const stack = [{
        label: '',
        children: []
      }];

      lines.forEach(line => {
        const level = line.match(/^#+/);
        const label = line.replace(/^#+/, '').trim();
        if (level) {
          const node = {
            label,
            children: []
          };
          let parent = stack[stack.length - 1];
          while (parent.level >= level[0].length) {
            stack.pop();
            parent = stack[stack.length - 1];
          }
          parent.children.push(node);
          stack.push({
            level: level[0].length,
            children: node.children
          });
        }
      });

      this.treeData = stack[0].children;
      console.log('treeData', this.treeData);
    },
    /**
     * 选择要移动的文件/文件夹
    * */
    moveFolder() {
      if (this.selection.some(item => (item.selectable || item.parentSyncStatus == 1))) {
        this.$message({
          message: this.$t('AISearch.cannotBeMoved'),
          duration: 5000,
          type: "warning"
        })
        return;
      }
      
      // nextExecuteFnName如果有值，说明校验过权限，需要放开正常执行
      if (this.nextExecuteFnName == "") {
        // 没有权限，记录当前执行函数名，用户再点击我知道了后继续执行
        if (!this.beforeExecuteCheck("moveFolder")) {
          return;
        }
      }
      let arr = JSON.parse(JSON.stringify(this.selection)).filter(item => { return (item.hasManagement || this.roleId == 1) })
      this.$emit('batchMoveFolder', arr)
    },
    /**
     * 选择父目录
     * */
    elDropdownvisibleChange(value) {
      if (value) {
        this.setChecked(this.parentId, this.directoryTree);
      }
      this.parentDirectorySelect = value;
    },
    /**
     * 选择要删除的文件/文件夹
     * */
    deleteFolder() {
      // nextExecuteFnName如果有值，说明校验过权限，需要放开正常执行
      if (this.nextExecuteFnName == "") {
        // 没有权限，记录当前执行函数名，用户再点击我知道了后继续执行
        if (!this.beforeExecuteCheck("deleteFolder")) {
          return;
        }
      }
      let arr = JSON.parse(JSON.stringify(this.selection)).filter(item => { return (item.hasManagement || this.roleId == 1) })
      this.$emit('batchDelete', arr)
    },
    /**
     * 取消选择
     * */
    cancelChecked() {
      this.$refs.knowledgeTable.clearSelection();
    },
    // 重新学习知识
    relearn(item) {
      console.debug('重新学习')
      item.learningFinished = false;
      item.learningFail = false;
      let url = requestUrl.knowledgeBase.relearnKnowledge + item.knowledgeId;
      this.$http.post(url).then(res => {
        if (res.status == 200) {
          this.$message({
            message: this.$t('knowledgeManagement.knowledgeTable.startRelearning'),
            type: 'success'
          })
        }
      })
    },
    getFolderAndFile(id, type) {
      console.log(id, type, 732);
      this.folderId = id;
      this.loading = true;
      this.selection = [];
      if (this.$refs.knowledgeTable) {
        this.$refs.knowledgeTable.clearFilter();
      }
      if (!id) {
        return
      }
      let corpId = sessionStorage.getItem('_corpId');
      let mainId = sessionStorage.getItem('_mainId');
      // let url = requestUrl.knowledgeBase.getFolderAndFile;
      let url = '';
      let isAskLightning = GetQueryString('isAskLightning') == 1
      if (isAskLightning) {
        url = requestUrl.knowledgeBase.clientSelectTreeFolderAndFile;
      } else {
        url = requestUrl.knowledgeBase.getFolderAndFile;
      }
      if (this.synchronizeFiles) {
        url = requestUrl.yuQueKnowledgeBase.selectTreeFolderAndFile
      }
      url += '?page=' + this.currentPage + '&size=' + this.pageSize + '&folderId=' + id + '&corpId=' + corpId + '&mainId=' + mainId;
      if (this.$route.query && this.$route.query.withLocationMark) {
        url = url + '&withLocationMark=true'
      }

      // if(type === 'isReload'){
      //   url = requestUrl.knowledgeBase.getFolderAndFile;
      // } else {
      if (this.channel === 5 && type !== 'isReload' && type !== 'del') {
        url = `${requestUrl.feishu.selectTreeFolderAndFile}?page=${this.currentPage}&size=${this.pageSize}&corpId=${corpId}&parentToken=${id}&justFolder=false`
      }
      // }
      if (isAskLightning) {
        let memberInDepartDto = JSON.parse(localStorage.getItem('memberInDepartDto'));
        this.$http.post(url, memberInDepartDto).then(res => {
          this.handlerAxiosData(res, id, type);
        })
      } else {
        this.$http.get(url).then(res => {
          if (res.data.code == '0') {
            if (res.data.data.list.length == 0) {
              console.log(this.$parent.breadcrumb.length, this.$parent.delData);
              // fix 批量操作在总数据为0 时上传数据后不隐藏
              if (res.data.data.total == 0) {
                this.showTags = false;
                // 当前文件夹下无数据时,加载父级数据
                if (type === 'del') {
                  let folderManagement = this.$parent.$refs.folderManagement
                  let treeBox = folderManagement.$refs.treeBox ? folderManagement.$refs.treeBox : (folderManagement.$refs.individualTreeBox ? folderManagement.$refs.individualTreeBox : '')
                  if (treeBox) {
                    treeBox.setCurrentKey(id);
                  }
                  this.currentPage = 1;
                  if (this.$parent.breadcrumb.length > 1) {
                    this.$parent.breadcrumb.pop();
                    if (this.$parent.delData.name !== '企业知识库') {
                      // this.$emit('update:currentNode',treeBox.getCurrentNode(this.$parent.delData.parentId))
                      this.getFolderAndFile(this.$parent.delData.parentId, type);
                    } else {
                      this.handlerAxiosData(res, id, type);
                    }
                  } else {
                    if (this.$parent.delData.name !== '企业知识库') {
                      // this.$emit('update:currentNode',treeBox.getCurrentNode(this.$parent.delData.parentId))
                      this.getFolderAndFile(this.$parent.delData.parentId, type);
                    } else {
                      this.handlerAxiosData(res, id, type);
                    }
                  }
                } else {
                  this.handlerAxiosData(res, id, type);
                }
              } else {
                // fix 删除当前页所有数据 请求页码不变导致数据为空
                if (res.data.data.total > 0 && this.currentPage > 1) {
                  this.currentPage = this.currentPage - 1;
                  this.getFolderAndFile(id, type);
                } else {
                  this.handlerAxiosData(res, id, type);
                }
              }
            } else {
              this.handlerAxiosData(res, id, type);
            }
          }
          // fix 批量操作在总数据为0 时上传数据后不隐藏
          // if (res.data.code == '0' && res.data.data.list.length == 0 && res.data.data.total == 0) {
          //   this.showTags = false;
          // }
          // // fix 删除当前页所有数据 请求页码不变导致数据为空
          // if (res.data.code == '0' && res.data.data.list.length == 0 && res.data.data.total > 0 && this.currentPage > 1) {
          //   this.currentPage = this.currentPage - 1;
          //   this.getFolderAndFile(id,type);
          // } else {
          //   this.handlerAxiosData(res,id,type);
          // }
          if (this.activeRow.id) {
            res.data.data.list.forEach(item => {
              if (this.activeRow.id === item.id) {
                // this.$parent.currentNode = item;
                this.activeRow = {}
              }
            })
          }
        })
      }
    },
    handlerAxiosData(res, id, type) {
      if (res.data.code == '0') {
        res.data.data.list.forEach((item) => {
          item.children = [];
          // item.showChildren = false;
          item.isLealf = true;
          if (this.channel === 5 && this.synchronizationDrawer) {
            item.visitPermissionList = JSON.parse(item.visitPermission) || [];
            item.visitPermissionList.forEach(item => {
              item.label = item.name;
            })
            item.fileSuffix = item.fileType;
            item.htmlOssPath = item.fileOssPath;
            // item.onlySee = true;
            // 0 文件夹  1 文件
            if (item.type === 1) {
              if (this.setSrc(item.fileSuffix) !== 'video' && this.setSrc(item.fileSuffix) !== 'image' && this.setSrc(item.fileSuffix) !== 'txt' && this.setSrc(item.fileSuffix) !== 'word' && this.setSrc(item.fileSuffix) !== 'pdf' && this.setSrc(item.fileSuffix) !== 'ppt' && this.setSrc(item.fileSuffix) !== 'excel' && this.setSrc(item.fileSuffix) !== 'html' && this.setSrc(item.fileSuffix) !== 'audio') {
                item.fileSelfType = 'other'
              } else {
                item.fileSelfType = this.setSrc(item.fileSuffix);
              }
            } else {
              item.fileSelfType = this.setSrc(item.fileSuffix);
            }
          }
        })
        this.tableData = res.data.data.list;
        //判断当前账号是否有管理权限
        this.tableData.forEach((item) => {
          // if (item.managePermission && JSON.parse(item.managePermission)){
          //   let grouIds = sessionStorage.getItem('_groupIds') ? JSON.parse(sessionStorage.getItem('_groupIds')) : [];
          //   let userId = sessionStorage.getItem('_uid') ? Number(sessionStorage.getItem('_uid')) : ""
          //   if (grouIds){
          //     grouIds = grouIds.map(res => {
          //       return Number(res)
          //     })
          //   }
          //   let managePermission = JSON.parse(item.managePermission).map(res => {
          //     return res.id
          //   });
          //   if (managePermission.includes(userId)){
          //     this.$set(item,'hasManagement',true)
          //   } else if (grouIds){
          //     for (let i=0;i<grouIds.length;i++){
          //       if (managePermission.includes(grouIds[i])){
          //         this.$set(item,'hasManagement',true);
          //         break;
          //       }
          //     }
          //   }
          // }
          
          // mode 数据
          // if (index > 0 && index <= 4 && index != 1) {
          //   this.$set(item, 'syncStatus', index);
          //   if (index > 0) {
          //     this.$set(item, 'syncEnable', true);
          //     this.$set(item, 'syncPath', "test999");
          //     this.$set(item, 'parentSyncStatus', 1);
          //   }
          // }

          this.$set(item, 'hasManagement', this.checkPermission(item));
        })
        this.tableTotal = res.data.data.total;
        this.hasNextPage = res.data.data.hasNextPage;
        this.loading = false;
        this.$emit('getDataTotal', res.data.data.total)
        if (type == 'del' && this.tableData.length > 0) {
          /**
           * 删除成功之后右侧详情显示更改
           * */
          this.$emit('deleteSuccess', this.tableData[0])
        }
        clearTimeout(this.aiLearningTimer);
        this.aiLearningTimer = null;
        if (!this.synchronizeFiles) {
          this.getLearnProgress(id);
        }
      }
    },
    getLearnProgress(id) {
      clearTimeout(this.aiLearningTimer);
      this.aiLearningTimer = null;
      let url = requestUrl.knowledgeBase.aiLearnProgress;
      let corpId = sessionStorage.getItem('_corpId')
      url += '?page=' + this.currentPage + '&size=' + this.pageSize + '&folderId=' + id + '&corpId=' + corpId;
      this.$http.get(url).then(res => {
        let findNoFinish = null
        if (res.data.code == 0 && res.data.data && res.data.data.list && res.data.data.list.length > 0) {
          this.tableData.forEach(item => {
            res.data.data.list.forEach(items => {
              if (item.id == items.id) {
                this.$set(item, 'supportLearn', items.supportLearn)

                // 更新AISearch 同步状态【立邦】
                this.$set(item, 'syncStatus', items.syncStatus)

                if (items.progress) {
                  this.$set(item, 'learningFinished', items.progress.finished)
                  this.$set(item, 'learningFail', items.progress.failed)
                  this.$set(item, 'processVal', items.progress.val)
                  if ((items.associatedTags || items.autoAssociatedTags)) {
                    this.$set(item, 'associatedTags', items.associatedTags)
                    this.$set(item, 'autoAssociatedTags', items.autoAssociatedTags)
                  }
                }
              }
              if (items.progress && !items.progress.finished){
                findNoFinish = items;
              }
            })
          })
          if (!findNoFinish){
            if (this.aiLearningTimer) {
              clearTimeout(this.aiLearningTimer)
              this.aiLearningTimer = null;
            }
          }
        }
        // this.tableData = [...this.tableData];
        if (!this.synchronizeFiles && findNoFinish) {
          this.aiLearningTimer = setTimeout(() => {
            if (this.aiLearningTimer) {
              clearTimeout(this.aiLearningTimer)
              this.aiLearningTimer = null;
            }
            this.getLearnProgress(id)
          }, 10000);
        }
      })
    },
    handleCurrentChange(value) {
      this.currentPage = value;
      this.getFolderAndFile(this.folderId)
    },
    /**
     * 开始同步事件
     * */
    starSynchronization() {
      let obj = RequiredVerification(this.selection)
      if (obj.bool) {
        this.$message({
          message: obj.message,
          duration: 2000,
          type: 'warning'
        })
        return
      }
      if (this.selection.length == 0) {
        this.$message({
          message: this.$t('knowledgeManagement.knowledgeTable.selectSyncFile'),
          duration: 2000,
          type: 'warning'
        })
        return;
      }
      this.checkNewData = this.selection.map(org => this.mapTree(org));
      let url = '';
      if (this.channel === 5) {
        url = requestUrl.feishu.syncFile + '?corpId=' + this.checkNewData[0].corpId;
      } else {
        url = requestUrl.yuQueKnowledgeBase.syncFile + '?cover=' + this.$refs.associatedTree.overwriteFile;
      }
      this.$http.post(url, this.checkNewData).then(res => {
        if (res.data.code == 0) {
          this.$message({
            message: this.$t('knowledgeManagement.knowledgeTable.syncSuccess'),
            duration: 2000,
            type: "success"
          })
          this.associatedDialog = false;
          this.associatedTree = false;
          let node = parentNode(this.breadcrumb)
          if (this.channel === 5) {
            this.getFolderAndFile(node.token)
          } else {
            this.getFolderAndFile(node.id)
          }
        }
      })
    },
    /**
     * 开始同步时一级的位置和权限是必填的
     * */

    /**
     * 取消同步关联事件
     * */
    cancelRelevanceFolder(ids) {
      let corpId = sessionStorage.getItem('_corpId')
      let url = '';
      if (this.channel === 5) {
        url = requestUrl.feishu.cancelSyncFile;
      } else {
        url = requestUrl.yuQueKnowledgeBase.cancelSyncFile
      }
      url += '?corpId=' + corpId
      this.$http.post(url, ids).then(res => {
        if (res.data.code == '0') {
          this.$message({
            message: this.$t('knowledgeManagement.knowledgeTable.disassociatedSuccess'),
            duration: 2000,
            type: 'success'
          })
          let node = parentNode(this.breadcrumb)
          console.log(node, 'node');
          if (this.channel === 5) {
            this.getFolderAndFile(node.token)
          } else {
            this.getFolderAndFile(node.id)
          }
        }
      })
    },
    // 点击文件名称区域进入下一级目录或预览
    nextOrPreview(row) {
      console.log('nextOrPreview');
      // 0:文件夹，1:文件
      if (row.type === 0) {
        this.currentPage = 1;
        this.getFolderAndFile(this.channel === 5 ? row.token : row.id)
        this.$emit('rowClick', row)
      } else {
        if (row.fileSelfType === 'other') {
          this.$message.warning(this.$t('knowledgeManagement.knowledgeTable.noSupportType'));
          return false;
        }
        this.$emit('lookDetail', row)
      }
    },
    //整行点击
    rowClick(row) {
      // 0:文件夹，1:文件
      if (row.type === 0) {
        // this.currentPage = 1;
        // this.getFolderAndFile(this.channel === 5 ? row.token : row.id)
        // this.$emit('rowClick',row)
      } else {
        if (row.fileSelfType === 'other') {
          this.$message.warning(this.$t('knowledgeManagement.knowledgeTable.noSupportType'));
          return false;
        }
        // this.$emit('lookDetail',row)
      }
      this.activeRow = row;
      this.$emit('lookDetail', row)
    },
    bridgecode(row) {
      return row.id
    },
    lookDetail(row) {
      if (row.fileSelfType === 'other') {
        this.$message.warning(this.$t('knowledgeManagement.knowledgeTable.noSupportType'));
        return false;
      }
      console.log(row, 'row');
      this.knowledgeItem = row;
      let fileInOssPath = row.ossPath ? row.ossPath : (row.htmlOssPath ? row.htmlOssPath : '')
      if (this.channel === 5 && this.synchronizationDrawer) {
        fileInOssPath = row.fileOssPath;
      }
      if (this.isAskLight()) {
        window.parent.postMessage({
          data: "doc_preview",
          item: JSON.stringify(row),
          url: fileInOssPath
        }, "*");
      } else {
        this.previewFileType = this.fileType(fileInOssPath);
        console.log(this.previewFileType);
        if (row.withLocationMark) {
          this.drawerDoc = true
          this.segmentationValue = row.processType ? row.processType : 'DEFAULT'
          this.$nextTick(() => {
            this.$refs.filePreview.withLocationMark = {
              id: row.knowledgeId,
              pageInfos: row.pageInfos,
              withLocationMark: true,
            }
          })
          return
        }
        let url = requestUrl.preview.previewUrl
        if (this.previewFileType === 'VIDEO') {
          url += '?needEncrypt=false'
        } else {
          url += '?needEncrypt=true'
        }
        this.$http.post(url, {
          fileInOssPath: fileInOssPath,
        }).then(res => {
          if (res.data.code == 0) {
            // let openUrl = 'https://test.open.askbot.cn/kkfileview/onlinePreview?url=' + res.data.data;
            console.debug('data', res.data)
            this.previewHref = res.data.data;
            console.log(this.previewFileType);
            if (!this.synchronizationDrawer) {
              // if(this.previewFileType !== 'HTML' && this.previewFileType !== 'OTHER'){
              //   this.drawerDoc = true;
              // } else {
              sessionStorage.setItem('breadcrumb', JSON.stringify(this.breadcrumb))
              this.$router.push({
                name: "versionManage",
                query: {
                  id: row.id,
                  fileType: this.previewFileType,
                  name: row.name,
                  knowledgeId: row.knowledgeId,
                  fileSuffix: row.fileSuffix
                }
              })
              // }
            } else {
              this.drawerDoc = true;
              this.$nextTick(() => {
                if (this.previewFileType === 'HTML') {
                  this.$refs.filePreview.getPreviewContent(fileInOssPath);
                }
              })
            }
          }
        })
      }
    },
    /**
     * 判断预览文件类型
     * */
    fileType(url) {
      if (url.indexOf('uploadId') !== -1) {
        url = this.removeURLParameter(url, 'uploadId');
      }
      const fileType = url.substring(url.lastIndexOf('.'));
      if (fileType === '.mp4' || fileType === '.MP4' || fileType === '.MOV' || fileType === '.mov' || fileType == '.avi' || fileType == '.flv') {
        return 'VIDEO'
      } else if (fileType === '.html') {
        return 'HTML'
      } else {
        return 'OTHER'
      }
    },
    // 删除url中指定的参数
    removeURLParameter(url, parameter) {
      var urlparts = url.split('?');
      if (urlparts.length >= 2) {
        //参数名前缀
        var prefix = encodeURIComponent(parameter) + '=';
        var pars = urlparts[1].split(/[&;]/g);

        //循环查找匹配参数
        for (var i = pars.length; i-- > 0;) {
          if (pars[i].lastIndexOf(prefix, 0) !== -1) {
            //存在则删除
            pars.splice(i, 1);
          }
        }

        return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
      }
      return url;
    },

    /**
     * 决定这一行的checkbox是否可以被勾选
     * */
    selectable(row) {
      let bool = false;
      this.selection.forEach(item => {
        if (item.associatedSyncStatus === row.associatedSyncStatus) {
          bool = true
        }
      })
      if (this.selection.length == 0) {
        bool = true
      }
      if (row.fileSelfType === 'other' && this.synchronizationDrawer) {
        bool = false
      }
      return bool
    },
    onlySeePermission(row) {
      let bool = true;
      // console.log(row.onlySee, row.hasManagement, this.roleId, 'this.roleIdthis.roleId');

      if (row.onlySee || (!row.hasManagement && this.roleId != 1)) {
        bool = false
      }
      return bool;
    },
    associated(type) {
      if (type == 'associated') {
        this.associatedDialog = true;
        this.associatedTree = true;
        this.selection = this.$refs.knowledgeTable.selection;
        this.selectionCopy = JSON.stringify(this.$refs.knowledgeTable.selection);
        // let list = [{
        //   label: '一级 1',
        //   id:1,
        //   children:[]
        // },{
        //   label: '二级 1',
        //   id:2,
        //   children:[]
        // }]
        // this.selection = list;
        // this.selectionCopy = JSON.stringify(list);
      }
      if (type === 'cancel') {
        if (this.channel === 5) {
          let tokens = this.selection.map(res => {
            return res.token;
          })
          this.cancelRelevanceFolder(tokens)
        } else {
          let ids = this.selection.map(res => {
            return res.id;
          })
          this.cancelRelevanceFolder(ids)
        }

      }
    },
    /**
     * 全选按钮隐藏
     */
    cellClass() {
      if (this.synchronizeFiles && this.selection.length === 0) {
        return 'DisableSelection'
      }
    },
    /**
     * 重新去选择文件或文件夹时保留当前数据
     * */
    chooseFolder() {
      this.associatedDialog = false;
    },
    /**
     * 取消同步同时清空刚才的配置
     * */
    cancelSync() {
      this.isClickOperationRelevance = false;
      this.associatedDialog = false;
      this.associatedTree = false;
      this.selection = JSON.parse(this.selectionCopy);

    },
    /**
     * 移除已经选择的数据
     * */
    removeFolder(row) {
      this.deleteCheckData(this.selection, row);
      this.$refs.knowledgeTable.toggleRowSelection(row, false);
    },
    /**
     * 同步时递归处理需要向后端保存的数据格式
     * */
    mapTree(res) {
      let haveChildren = Array.isArray(res.children) && res.children.length > 0;
      return {
        "feishuToken": res.token,
        "corpId": res.corpId,
        "parentId": res.parentId,
        "name": res.name,
        "orderNum": res.orderNum,
        "ossPath": res.htmlOssPath,
        "type": res.type,
        "fileSuffix": res.fileSuffix,
        "volume": res.volume,
        "yuQueId": res.yuQueId,
        "visitPermissionList": res.visitPermissionList.map(vis => {
          let objs = {
            "id": vis.id,
            "type": vis.type || vis.type == 0 ? vis.type : (vis.source ? 0 : 1),
            "name": vis.label || vis.name
          }
          return objs
        }),
        "children": haveChildren ? res.children.map(i => this.mapTree(i)) : [],
      }
    },
    /**
     * 移除已选择数据时遍历查询
     * */
    deleteCheckData(list, row) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].id == row.id) {
          list.splice(i, 1)
          break;
        }
        if (list[i].children) {
          this.deleteCheckData(list[i].children, row)
        }
      }
    },
    /**
     * 同步第三方
    * */
    syncThird() {
      if (this.channelList.length === 0) {
        this.notBindPopup = true;
      } else {
        this.$emit('syncThird', this.channelList[0].channel);
      }
    },
    /**
     * 同步第三方 选择一个渠道进行同步
    * */
    handleCommandChooseChannel(command) {
      this.$emit('syncThird', command)
    },
    /**
     * 打开目录管理
     * */
    openDirectoryDialog() {
      this.$emit('openDirectoryDialog')
    },
    /**
    * 通知子组件打开标注功能
    * */
    openMark() {
      this.markFlag = !this.markFlag;
      this.$refs.filePreview.openMark()
    },
    getChannel() {
      this.$http.get(requestUrl.channel.getAccessChannel + '?corpId=' + sessionStorage.getItem('_corpId')).then(res => {
        if (res.data.code == 0 && res.data.data) {
          this.channelList = [];
          res.data.data.forEach(item => {
            item.channelName = handlerChannel(item.channel);
            item.channelImg = handlerChannelImg(item.channel);
            if (item.channel === 5 && !this.channelList.some(item => { return item.channel === 5 })) {
              this.channelList.push(item);
            } else if (item.channel === 8 && !this.channelList.some(item => { return item.channel === 8 })) {
              this.channelList.push(item);
            }
          })
          sessionStorage.setItem('channelNum', this.channelList.length)
        }
      })
    },
    goBind() {
      this.$router.push({
        path: '/channel_management',
      })
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.fileSelfType === 'other') {
        return 'other-file-row';
      } else if (rowIndex === 3) {
        return '';
      }
    },
    goManage() {
      // console.log(localStorage.getItem('authStatus'),'authStatus');

      this.$http.post(requestUrl.company.getCompanyToken, {
        username: localStorage.getItem('username'),
        tel: localStorage.getItem('phone'),
        mainId: localStorage.getItem('_mainId'),
        profilePhoto: localStorage.getItem('profilePhoto'),
      }).then((res) => {
        // this.$http.post(requestUrl.company.getCompanyToken+'?username=' + localStorage.getItem('username') + '&mainId=' + localStorage.getItem('_mainId')+'&tel=' + localStorage.getItem('phone') ).then((res) => {
        console.log(res, 'token');
        this.popoverVisible = false;
        if (res.data) {
          let redirectUrl = redirectUri[process.env.VUE_APP_NODE_ENV] ? redirectUri[process.env.VUE_APP_NODE_ENV].wisdom : redirectUri['development'].wisdom;
          let tempwindow = window.open("_blank");
          // 未认证企业跳转全局管理后台进行认证,已认证企业跳转企业知识管理后台
          if (localStorage.getItem('authStatus') == 'false') {
            redirectUrl = redirectUri[process.env.VUE_APP_NODE_ENV] ? redirectUri[process.env.VUE_APP_NODE_ENV].callback : redirectUri['development'].callback;
            tempwindow.location = redirectUrl + '#/auth' + '?token=' + res.data + '&isAskLightning=1';
          } else {
            tempwindow.location = redirectUrl + '/#/?token=' + res.data;
          }
        } else {
          this.messageFn('error', res.message);
        }
      });

    },
    batchSetAuthority() {
      // nextExecuteFnName如果有值，说明校验过权限，需要放开正常执行
      if (this.nextExecuteFnName == "") {
        // 没有权限，记录当前执行函数名，用户再点击我知道了后继续执行
        if (!this.beforeExecuteCheck("batchSetAuthority")) {
          return;
        }
      }
      this.permissionDialog = true;
      this.permissionList = []
      this.$nextTick(() => {
        this.$refs.knowledgePermissions.knowledgePermissions = true;
      })
    },
    onNodeClick(data) {
      if (data.checked) {
        this.permissionList.push(data)
      } else {
        this.permissionList = this.permissionList.filter(item => {
          return item.id !== data.id
        });
      }
    },
    confirmPermission(obj) {
      if (this.saveLoading) {
        this.$message({
          message: this.$t('knowledgeManagement.folderManagement.saving'),
          type: "warning"
        })
        return;
      }
      let permissionList = obj.permissionsList.map(res => {
        let obj = {
          id: res.id,
          type: res.type !== undefined ? res.type : (res.source !== undefined ? 0 : 1),
          name: res.name ? res.name : res.label
        }
        return obj
      });
      let ids = [];
      this.selection.forEach(item => {
        if (item.hasManagement) {
          ids.push(item.id)
        }
      })
      let managePermission = obj.accountManagement;
      let editPermission = obj.editPermissions
      let param = {
        visitPermission: permissionList,
        managePermission,
        editPermission,
        ids,
        textWatermark: obj.textWatermark,
        textWatermarkValue: obj.textWatermarkValue,
        isDownload: obj.isDownload,
        downloadType: obj.downloadType
      }
      if (permissionList.length == 0) {
        this.$message({
          message: this.$t('knowledgeManagement.knowledgeTable.visitRequired'),
          type: "warning"
        })
        return;
      }
      console.log(param, 'permissionList');
      let corpId = sessionStorage.getItem('_corpId');
      let url = requestUrl.knowledgeBase.batchPermissionSetting + '?corpId=' + corpId;
      this.saveLoading = true;
      this.$http.post(url, param).then(res => {
        if (res.data.code == '0') {
          this.$message({
            message: this.$t('knowledgeManagement.knowledgeTable.setPermissionSuccess'),
            type: 'success',
            duration: 2000
          })
          this.$refs.knowledgeTable.clearSelection();
          this.$refs.knowledgePermissions.knowledgePermissions = false;
          this.permissionDialog = false;
          this.getFolderAndFile(this.folderId);
        }
        this.saveLoading = false;
        this.nextExecuteFnName == ""
      })
    },
    cancelPermission() {
      this.$refs.employeeStructure.permissionDialog = false;
      this.permissionDialog = false;
    },
    changeSegementation() {
      this.segmentationVisible = true
    },
    sureSegmentation() {
      this.$http.put('/knowledge-api/knowledge/process-type/' +
        this.knowledgeItem.knowledgeId + '?type=' + this.segmentationValue).then(res => {
          console.log(res)
          if (res.data.code == 0) {
            this.$message.success(this.$t('knowledgeManagement.knowledgeTable.switchSegSuccess'))
            this.drawerDoc = false
            this.segmentationVisible = false
            console.log(this.folderId, 'folderIdfolderIdfolderId');
            this.getFolderAndFile(this.folderId, '')
          } else {
            this.$message.warning(this.$t('knowledgeManagement.knowledgeTable.switchSegFail'))
          }
        })
    },
    // 校验权限，批量发布时，有编辑 || 管理权限 才能操作
    checkPermission(item) {
      let flag = false
      // 整体的权限列表 ，包含部门 和 个人 ， tyoe 1 代表 部门 ， 0 代表 个人
      let managePermission = item.managePermission ? JSON.parse(item.managePermission) : []
      let manageableGroupIds = managePermission.filter(item => { return item.type == 1 }).map(item => { return item.id })
      let manageableUserIds = managePermission.filter(item => { return item.type == 0 }).map(item => { return item.id })

      let editPermission = item.editPermission ? JSON.parse(item.editPermission) : []
      let editGroupIds = editPermission.filter(item => { return item.type == 1 }).map(item => { return item.id })
      let editUserIds = editPermission.filter(item => { return item.type == 0 }).map(item => { return item.id })

      let accountGroupIds = sessionStorage.getItem("_groupIds") ? JSON.parse(sessionStorage.getItem("_groupIds")) : []
      let accountUserId = sessionStorage.getItem("_uid") * 1

      if (editUserIds.includes(accountUserId) || manageableUserIds.includes(accountUserId)) {
        flag = true
        return flag
      }
      for (let index = 0; index < accountGroupIds.length; index++) {
        const gIds = accountGroupIds[index] * 1;
        if (editGroupIds.includes(gIds) || manageableGroupIds.includes(gIds)) {
          flag = true
          return flag
        }
      }
      return flag
    },
    batchPublish() {
      // nextExecuteFnName如果有值，说明校验过权限，需要放开正常执行
      if (this.nextExecuteFnName == "") {
        // 没有权限，记录当前执行函数名，用户再点击我知道了后继续执行
        if (!this.beforeExecuteCheck("batchPublish")) {
          return;
        }
      }
      let arr = JSON.parse(JSON.stringify(this.selection)).filter(item => { 
        return item.currentVersion.state != "PUBLISH" &&
            item.currentVersion.state != "PUBLISHING" &&
              (item.hasManagement || this.roleId == 1) && 
              item.type == 1
      })
      if (arr.length !== 0) {
        this.batchOperateType = "batchPublish"
        this.$http.post(requestUrl.knowledgeBase.batchPublishOrArchive, {
          operationType: 'KNOWLEDGE_PUBLISH',
          knowledgeBaseStructureIds: arr.map(item => { return item.id })
        }).then(res => {
          if (res.data.code == 0) {
            if (res.data.data.needApprovalKnowledge && res.data.data.needApprovalKnowledge.length > 0) {
              this.needApprovalVisible = true
              this.needApprovalKnowledge = res.data.data.needApprovalKnowledge
            } else {
              this.$message.success(this.$t('status.publishSuccess'))
              this.getFolderAndFile(this.folderId, '')
            }
          }
        })
      } else {
        this.$message.warning(this.$t('knowledgeManagement.knowledgeTable.noPublish'))
      }
      this.nextExecuteFnName = ""
    },
    batchUnPublish() {
      // nextExecuteFnName如果有值，说明校验过权限，需要放开正常执行
      if (this.nextExecuteFnName == "") {
        // 没有权限，记录当前执行函数名，用户再点击我知道了后继续执行
        if (!this.beforeExecuteCheck("batchUnPublish")) {
          return;
        }
      }
      let arr = JSON.parse(JSON.stringify(this.selection)).filter(item => { 
        return (item.currentVersion.state == "PUBLISH" || item.currentVersion.state == "PUBLISHING") && (item.hasManagement || this.roleId == 1) && item.type == 1
      })
      if (arr.length != 0) {
        this.batchOperateType = "batchUnPublish"
        this.$http.post(requestUrl.knowledgeBase.batchPublishOrArchive, {
          operationType: 'KNOWLEDGE_CANCEL_PUBLISH',
          knowledgeBaseStructureIds: arr.map(item => { return item.id })
        }).then(res => {
          // 批量成功后, 都成功，或需要审批
          if (res.data.code == 0) {
            if (res.data.data.needApprovalKnowledge && res.data.data.needApprovalKnowledge.length > 0) {
              this.needApprovalVisible = true
              this.needApprovalKnowledge = res.data.data.needApprovalKnowledge
            } else {
              this.$message.success(this.$t('status.cancelPublishSuccess'))
              this.getFolderAndFile(this.folderId, '')
            }
          }
        })
      } else {
        this.$message.warning(this.$t('knowledgeManagement.knowledgeTable.noUnPublish'))
      }
      this.nextExecuteFnName = ""
    },
    batchDocumentation() {
      // nextExecuteFnName如果有值，说明校验过权限，需要放开正常执行
      if (this.nextExecuteFnName == "") {
        // 没有权限，记录当前执行函数名，用户再点击我知道了后继续执行
        if (!this.beforeExecuteCheck("batchDocumentation")) {
          return;
        }
      }
      let arr = JSON.parse(JSON.stringify(this.selection)).filter(item => { 
        return item.currentVersion.state != "ARCHIVE" && (item.hasManagement || this.roleId == 1) && item.type == 1
      })
      if (arr.length != 0) {
        this.batchOperateType = "batchDocumentation"
        this.$http.post(requestUrl.knowledgeBase.batchPublishOrArchive, {
          operationType: 'KNOWLEDGE_ARCHIVE',
          knowledgeBaseStructureIds: arr.map(item => { return item.id })
        }).then(res => {
          // 批量成功后, 都成功，或需要审批
          if (res.data.code == 0) {
            if (res.data.data.needApprovalKnowledge && res.data.data.needApprovalKnowledge.length > 0) {
              this.needApprovalVisible = true
              this.needApprovalKnowledge = res.data.data.needApprovalKnowledge
            } else {
              this.$message.success(this.$t('status.documentationSuccess'))
              this.getFolderAndFile(this.folderId, '')
            }
          }
        })
      } else {
        this.$message.warning(this.$t('knowledgeManagement.knowledgeTable.noDocumentation'))
      }
      this.nextExecuteFnName = ""
    },
    batchCancelDocumentation() {
      // nextExecuteFnName如果有值，说明校验过权限，需要放开正常执行
      if (this.nextExecuteFnName == "") {
        // 没有权限，记录当前执行函数名，用户再点击我知道了后继续执行
        if (!this.beforeExecuteCheck("batchCancelDocumentation")) {
          return;
        }
      }
      let arr = JSON.parse(JSON.stringify(this.selection)).filter(item => { 
        return item.currentVersion.state == "ARCHIVE" && (item.hasManagement || this.roleId == 1) && item.type == 1
      })
      if (arr.length != 0) {
        this.batchOperateType = "batchCancelDocumentation"
        this.$http.post(requestUrl.knowledgeBase.batchPublishOrArchive, {
          operationType: 'KNOWLEDGE_CANCEL_ARCHIVE',
          knowledgeBaseStructureIds: arr.map(item => { return item.id })
        }).then(res => {
          if (res.data.code == 0) {
            if (res.data.data.needApprovalKnowledge && res.data.data.needApprovalKnowledge.length > 0) {
              this.needApprovalVisible = true
              this.needApprovalKnowledge = res.data.data.needApprovalKnowledge
            } else {
              this.$message.success(this.$t('status.cancelDocumentationSuccess'))
              this.getFolderAndFile(this.folderId, '')
            }
          }
        })
      } else {
        this.$message.warning(this.$t('knowledgeManagement.knowledgeTable.noCancelDocumentation'))
      }
      this.nextExecuteFnName = ""
    },
    ImSure() {
      this.checkPermissionVisible = false
      if (this.nextExecuteFnName) {
        this[this.nextExecuteFnName]()
      }
    },
    beforeExecuteCheck(fnName) {
      this.notHasPermissionList = [];
      this.selection.forEach(item => {
        if (!item.hasManagement && this.roleId != 1) {
          this.notHasPermissionList.push(item)
        }
      })
      if (this.notHasPermissionList.length > 0) {
        if (this.notHasPermissionList.length == this.selection.length) {
          // TODO
          // 所有文件都没有权限，直接提示没有操作权限
          this.$message.warning(this.$t('knowledgeManagement.knowledgeTable.noPermission'))
          return false
        } else {
          this.checkPermissionVisible = true
          this.nextExecuteFnName = fnName
          return false;
        }
      } else {
        return true
      }
    },
    switchOperateType() {
      switch (this.batchOperateType) {
        case "batchPublish":
          return this.$t("status.publishSuccess")
        case "batchUnPublish":
          return this.$t("status.cancelPublishSuccess")
        case "batchDocumentation":
          return this.$t("status.documentationSuccess")
        case "batchCancelDocumentation":
          return this.$t("status.cancelDocumentationSuccess")
      }
    }
  },
  created() {
    that = this
  },
  mounted() {
    let isAskLightning = GetQueryString('isAskLightning') == 1
    if (!isAskLightning) {
      this.getChannel();
    }
    // this.getFolderAndFile(-1);
    if (this.synchronizationDrawer && this.channel === 5) {
      this.typeList.splice(0, 1)
    }
    this.haveKnowledgePermission = GetQueryString('haveKnowledgePermission');
    this.roleId = sessionStorage.getItem('roleId')
  },
  beforeDestroy() {
    // if (this.aiLearningTimer) {
    //   clearTimeout(this.aiLearningTimer)
    //   this.aiLearningTimer = null;
    // }
  }
};
</script>
<style lang="less">
.folder-status-config-popper {
  padding: 0 !important;
}
.el-message{
  z-index: 999999 !important;
}
.sync-batch-enable {
  .sync-batch-enable-header {
    height: 36px;
    line-height: 36px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .sync-batch-enable-header-name {
      flex: auto;
    }
    .sync-batch-enable-header-local {
      width: 160px;
      .sync-batch-enable-header-local-require{
        color: red;
      }
    }
    .sync-batch-enable-header-handle {
      width: 30px;
      text-align: right
    }
  }
  .sync-batch-enable-list {
    display: flex;
    flex-direction: column;
    .sync-batch-enable-list-cell {
      height: 38px;
      line-height: 38px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      align-items: center;
      .sbel-name {
        flex: auto;
        display: flex;
        align-items: center;
        max-width: 200px;
        img {
          width: 20px;
        }
        .docName {
          margin-left: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .sbel-path{
        width: 160px;
        .sbel-path-tip-change {
          display: block;
          height: 32px;
          line-height: 32px;
          background-color: #A9B3C6;
          cursor: not-allowed;
          opacity: 0.25;
          padding: 0 12px;
          border-radius: 5px;
        }
      }
      .sbel-handle{
        text-align: right;
        width: 30px;
        color: #366AFF;
        cursor: pointer;
      }
    }
  }
  .sync-batch-enable-tip{
    margin-top: 12px;
    font-size: 12px;
    color: #A9B3C6;
  }
}
.folder-status-config {
  border-radius: 5px;
  .folder-status-config-header-tip{
    font-size: 12px;
    height: 46px;
    line-height: 46px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(to bottom, #FFFFFF, #ECF1FF);
  }
  .folder-status-config-header-tip-err {
    font-size: 12px;
    height: 46px;
    line-height: 46px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(to bottom, #FFFFFF, #FFE8EA);
    .fscht-handle-err {
      width: 50px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      cursor: pointer;
      background-color: #366AFF;
      border-radius: 12px;
      padding: 0 10px;
      color: #ffffff;
    }
  }
  .folder-status-config-header{
    height: 26px;
    padding: 0 16px;
    margin-top: 10px
  }
  .folder-status-config-local{
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .fscl-title{
      height: 36px;
      line-height: 36px;
      font-weight: 600;
    }
    .fscl-title-tip{
      font-size: 11px;
      color: #A9B3C6;
      margin-bottom: 4px;
    }
    .fscl-tip {
      font-size: 11px;
      color: #A9B3C6;
      margin-top: 10px;
      margin-bottom: 16px;
    }
  }
  .folder-status-config-footer{
    padding: 0 16px;
    height: 30px;
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
    .fscf-concel{
      border-radius: 12px;
      height: 24px;
      line-height: 24px;
      padding: 0 10px;
      border: 1px solid #DDE0E9;
      text-align: center;
      cursor: pointer;
      margin-right: 12px;
    }
    .fscf-confirm{
      border-radius: 12px;
      height: 24px;
      line-height: 24px;
      padding: 0 10px;
      border: 1px solid #366AFF;
      text-align: center;
      cursor: pointer;
      background-color: #366AFF;
      color: white;
    }
  }
}
.dropdown-channel-menu {
  .el-dropdown-menu__item {
    display: flex;
    align-items: center;

    img {
      width: 22px;
      margin-right: 6px;
    }

  }

}
</style>
<style scoped lang="less">
#knowledgeTable {
  position: relative;
  height: 100%;
  border-radius: 9px;
  box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);

  ::v-deep .el-table .DisableSelection .cell .el-checkbox__inner {
    display: none;
  }

  .table {
    border-radius: 9px;
    height: calc(100% - 50px);

    .folder-status {
      display: flex;
      align-items: center;
      justify-content: center;
      .folder-status-label{
        padding: 0 4px;
        height: 19px;
        line-height: 19px;
        font-size: 12px;
        border-radius: 2px;
      }
      .folder-status-synchronization {
        color: #366aff;
        background: #ECF1FF;
        border: 1px solid #ECF1FF;
      }
      .folder-status-nosynchronization{
        color: #9DA5BF;
        background: #F3F3F3;
        border: 1px solid #DDE0E9;
      }
    }
    .file-status {
      display: flex;
      align-items: center;
      justify-content: center;
      .file-status-label{
        padding: 0 4px;
        height: 19px;
        line-height: 19px;
        font-size: 12px;
        border-radius: 2px;
      }
      .file-status-synchronization-ing {
        border: 1px solid #FFE0CF;
        color: #FF8C4C;
        background: #FFF1E9;
      }
      .file-status-synchronization-no{
        color: #9DA5BF;
        background: #F3F3F3;
        border: 1px solid #DDE0E9;
      }
      .file-status-synchronization-succ {
        color: #366aff;
        background: #ECF1FF;
        border: 1px solid #ECF1FF;
      }
      .file-status-synchronization-err{
        border: 1px solid #FFE0CF;
        color: #FF8C4C;
        background: #FFF1E9;
      }
    }

    .docType {
      display: flex;
      align-items: center;

      .docLeft {
        display: flex;
        align-items: center;
        flex: none;
        max-width: 70%;

        .docName {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 100%;
        }

        .version-num {
          background-color: #ECF1FF;
          padding: 0 5px;
          height: 19px;
          line-height: 19px;
          border-radius: 2px;
          color: #366aff;
          font-size: 12px;
          margin-left: 10px;
        }
      }

      .newsStatus {
        flex: none;
        //width: 44px;
        padding: 0 4px;
        height: 19px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        margin-left: 10px;
      }

      .unPublishStatus {
        border: 1px solid #FFE0CF;
        color: #FF8C4C;
        background: #FFF1E9;
      }

      .publishStatus {
        color: #366aff;
        background: #ECF1FF;
        border: 1px solid #ECF1FF;
      }

      .archiveStatus {
        color: #9DA5BF;
        background: #F3F3F3;
        border: 1px solid #DDE0E9;
      }

      img {
        margin-right: 4px;
      }

      .folder-icon {
        margin-right: 6px;
        height: 18px;
      }
    }

    .adicic-cell-handle-learning {
      margin-right: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .achl-image {
        height: 17px;
        line-height: 17px;
        width: 17px;
        border-radius: 50%;
        display: flex;
        align-items: center;

        .iconfont {
          color: #366aff;
          font-size: 16px;
        }
      }

      .achl-text {
        margin-left: 4px;
        font-size: 12px;
        font-weight: 700;
        color: #366aff;
      }

      .achl-tip {
        font-size: 12px;
        color: #A9B3C6;
        margin-left: 4px;
        cursor: pointer;
      }

      .guoran-xuexishibai {
        color: #FE5965;
      }

      .fail-text {
        color: #FE5965;
      }
    }

    img {
      // margin-right: 4px;
    }

    /deep/.el-table {
      border-radius: 9px;

      th {
        padding: 0;
      }

      .file-name {
        height: 48px;
        padding: 0;

        .cell {
          height: 48px;
          line-height: 48px;
        }
      }
    }

    .el-dropdown-link {
      display: flex;
      align-items: center;
      color: #000000;

      i {
        font-size: 13px;

      }
    }

    .operation {
      i {
        color: #366aff;
      }
    }

    /deep/.el-checkbox__label {
      display: none;
    }

    .associatedSyncStatus {
      // width: 75px;
      flex-shrink: 0;
      padding: 0 8px;
      height: 20px;
      background: #ECF1FF;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #366aff;
      margin-left: 20px;
      font-size: 12px;
    }

    .previousStatus {
      flex: none;
      padding: 0 4px;
      height: 19px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      margin-left: 10px;
      color: #366aff;
      background: #ECF1FF;
      border: 1px solid #ECF1FF;
    }

    .selectData-box {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .selectData {
      position: absolute;
      bottom: 64px;
      height: 44px;
      background: rgba(52, 68, 115, .8);
      border-radius: 5px;
      z-index: 99;
      display: flex;
      align-items: center;
      justify-content: center;

      .tagItem {
        padding: 0 12px;
        border-right: 1px solid #FFFFFF;
        color: #FFFFFF;
        font-size: 13px;
        cursor: pointer;
        display: flex;
        align-items: center;
      }

      .tagItem:nth-last-child(1) {
        border-right: none;
      }

      .checkTotal {
        flex: none;
        padding: 0 10px;
        height: 20px;
        background: #ECF1FF;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #366aff;
        margin-left: 8px;
      }
    }

    .updateTime {
      display: flex;
      align-items: center;

      .guoran-a-18-15 {
        color: #D2D8E3;
        margin-right: 5px;
      }
    }

    /deep/.el-table__fixed {
      height: auto !important; // 此处的important表示优先于element.style
      bottom: 14px; // 改为自动高度后，设置与父容器的底部距离，则高度会动态改变

      &::before {
        height: 0;
      }
    }

    /deep/.el-table__fixed-right {
      height: auto !important; // 此处的important表示优先于element.style
      bottom: 14px; // 改为自动高度后，设置与父容器的底部距离，则高度会动态改变

      &::before {
        height: 0;
      }
    }

    /deep/.other-file-row {
      background-color: #f4f4f5 !important;
    }
  }

  .emptyState {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: white;
    border-radius: 9px;

    .tips {
      text-align: center;

      .tips-1 {
        margin-bottom: 10px;
      }

      .tips-2 {
        color: #A9B3C6;
        margin-bottom: 10px;
      }
    }

    .synchronousUpload {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 12px;

      .buttonItem {
        flex: none;
        padding: 0 8px;
        height: 30px;
        background: #FFFFFF;
        border: 1px solid #A1B9FF;
        border-radius: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        color: #366aff;
        cursor: pointer;
        margin-right: 14px;

        i {
          font-size: 14px;
          margin-right: 4px;
        }

        .el-dropdown-link {
          color: #366aff;
          font-size: 13px;
        }
      }
    }

    /deep/.el-dropdown {
      img {
        width: 20px;
      }
    }
  }

  .choose-popup {
    /deep/#popup-assembly {
      // width: 75%;
      width: 1066px;

      .popup-container-c {
        padding: 0 50px;
      }
    }
  }

  .synchronizeDialog {
    /deep/.defaultDialog {
      #popup-assembly {
        width: 600px !important;
      }
    }
  }
}

.dropdown-menu {
  i {
    font-size: 14px;
    color: #404040;
  }

  .text {
    color: #000000;
  }

  /deep/.uploader-btn {
    border: none;
    color: #606266;
  }

  .uploader-btn:hover {
    background-color: none;
  }
}

.pre_title {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 20px 0 10px;

  .iconfont {
    cursor: pointer;
  }

  .close {
    width: 20px;
    margin-right: 15px;

    i {
      font-size: 12px;
    }
  }

  .title {
    width: calc(100% - 250px);
    text-align: left;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .el-input {
      height: 100%;
      width: 90%;

      /deep/.el-input__inner {
        padding: 0;
        border: none;
      }
    }
  }

  .title_right {
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

/deep/.el-drawer__body {
  height: 100%;
}

.pre_cont {
  width: 100%;
  height: calc(100% - 40px);
}

:focus {
  outline: none;
}

.popupAssociated {
  overflow: hidden;

  .title {
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    .numTag {
      flex: none;
      padding: 2px 6px;
      background: #ECF1FF;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 6px;
      color: #366aff;
      font-size: 12px;
    }
  }
}

.segmentation_popup {
  z-index: 9999;

  /deep/.popup-container {
    text-align: center;
    font-size: 14px;

    span {
      color: #366AFF;
    }
  }

}

.check_permission_popup,
.batch_approve_popup {
  z-index: 9999;

  /deep/#popup-assembly {
    // width: 75%;
    width: 550px;

    .popup-container-c {
      padding: 0 50px;
    }
  }

  /deep/.popup-container {
    font-size: 14px;
  }


  .check_permission_content {
    max-height: 400px;
    overflow: hidden;
    overflow-y: auto;
    text-align: left;

    .title {
      font-weight: 500;
    }

    p {
      box-sizing: border-box;
      padding-left: 10%;
      margin: 18px 0;
      display: flex;
      align-items: center;

      span {
        color: #000000;
      }

      img {
        width: 16px;
        height: 16px;
        margin-right: 6px;
      }

      .folder-icon {
        margin-right: 6px;
        height: 18px;
      }
    }
  }
}
</style>
