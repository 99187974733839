<template>
  <div class="vue-office-docx">
    <div class="vue-office-docx-main" id="vue-office-docx-main" ref="rootRef"></div>
  </div>
</template>

<script>
import newDocx from "@/components/preview/doc/docx";
import waterMark from "@/utils/common";
import { mapGetters } from 'vuex'
import { setTimestamp } from '@/assets/js/common'
export default {
  name: "vueDoc",
  data(){
    return{

    }
  },
  props:{
    src:{
      type:String,
      default:""
    },
    requestOptions:{
      type:Object,
      default(){
        return {}
      }
    },
    options:{
      type:Object,
      default(){
        return {}
      }
    },
    editType:{
      type:String,
      default:""
    }
  },
  computed:{
    ...mapGetters('knowledge', {
      textWatermarkStr: 'getTextWatermarkStr'
    }),
  },
  methods:{
    init() {
      let fileData = null;
      let container = this.$refs.rootRef;
      let src = this.src
      if (this.editType !== 'copyNewVersion'){
        src = setTimestamp(src)
      }
      newDocx.getData(src, this.requestOptions).then(async res => {
        fileData = await newDocx.getBlob(res);
        newDocx.render(fileData, container, this.options).then(() => {
          if (this.textWatermarkStr){
            waterMark.newInitWaterMark('vue-office-docx-main',this.textWatermarkStr)
          }
        }).catch(e => {
          newDocx.render('', container, this.options);
          console.log(e)
        });
      }).catch(e => {
        newDocx.render('', container, this.options);
        console.log('e',e)
      });
    }
  },
  mounted() {
    this.init()
  }
};
</script>

<style scoped lang="less">
.vue-office-docx {
  //height: 100%;
  //overflow-y: auto;
  .vue-office-docx-main{
    position: relative;
  }
  /deep/.docx-wrapper {
    background: #F5F7FB!important;
    padding: 0 14px;
    > section.docx {
      margin-bottom: 14px!important;
      width: 100% !important;
      //box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07)!important;
      box-shadow: none!important;
      padding: 20px!important;
      border-radius: 5px;
      span{
        //font-family: "Microsoft YaHei"!important;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .vue-office-docx {
    height: 100%;
    overflow-y: auto;
    /deep/.docx-wrapper {
      padding: 10px 0;
      background: #ededf0!important;
      width: 100%;
      height: calc(100% - 20px);
      > section.docx {
        padding:10px !important;
        width: 100% !important;
        box-shadow: none;
        margin-bottom: 10px!important;
        overflow-x: scroll;
        span{
          font-family: Sans-Serif;
        }
        div{
          width: 100% !important;
        }
        img{
          max-width: 100%;
        }
        td{
          border-color: #000000!important;
          border-width: 1px;
        }
      }

    }
  }
}
</style>